import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Input from './Input'
import Button from './Button'

const CoLessees = ({
  mainLeaseInputHandler,
  data,
  disabled,
  options,
  IS_ADMIN,
}) => {
  const [coLesseesRenderData, setCoLesseesRenderData] = useState([])
  const [coLesseesData, setCoLesseesData] = useState({})

  useEffect(() => {
    // lessees stored as JSON in DB show them on the form requested
    if (data?.co_lessees) {
      const deserializedRawCoLessees = data.co_lessees
      const numCoLessees = Object.keys(deserializedRawCoLessees).length / 2
      const newCoLesseesRenderData = Array(numCoLessees).fill('co-lessee')
      setCoLesseesData(deserializedRawCoLessees)
      setCoLesseesRenderData(newCoLesseesRenderData)
    }
  }, [])

  const onCoLesseeChange = e => {
    const coLesseeDataUpdated = {
      ...coLesseesData,
      [e.target.name]: e.target.value,
    }

    // for internal state
    setCoLesseesData(coLesseeDataUpdated)

    // for main lease state
    mainLeaseInputHandler({
      target: {
        name: 'co_lessees',
        value: coLesseeDataUpdated,
      },
    })
  }

  const onCoLesseeDelete = index => {
    // remove from internal state
    const tempCoLessees = [...coLesseesRenderData]
    tempCoLessees.pop()
    setCoLesseesRenderData(tempCoLessees)
    delete coLesseesData[`co_lessee_name_${index}`]
    delete coLesseesData[`co_lessee_email_${index}`]
    setCoLesseesData(coLesseesData)

    // remove from main lease state if present
    if (
      data?.co_lessees?.[`co_lessee_name_${index}`] &&
      data?.co_lessees?.[`co_lessee_email_${index}`]
    ) {
      delete data?.co_lessees[`co_lessee_name_${index}`]
      delete data?.co_lessees[`co_lessee_email_${index}`]
    }
  }

  const renderCoLessees = () =>
    coLesseesRenderData.map((_, i) => {
      const coLesseeName = `co_lessee_name_${i + 1}`
      const coLesseeEmail = `co_lessee_email_${i + 1}`

      return (
        <div className='co-lessee-row' key={`row-${i}`}>
          {options.showName && (
            <Input
              label={`Co-Lessee ${i + 1} Name`}
              name={coLesseeName}
              onChange={onCoLesseeChange}
              value={coLesseesData[coLesseeName] || ''}
              required
              disabled={disabled || options.showEmail}
              fullBorderStyles={{ width: '100%', maxWidth: 490 }}
              append={
                i + 1 === coLesseesRenderData.length &&
                !options.showEmail &&
                !disabled && (
                  <Button
                    style={{ margin: '0 10px' }}
                    buttonClass='button-danger'
                    onClick={() => onCoLesseeDelete(i + 1)}
                    disabled={disabled}
                  >
                    X
                  </Button>
                )
              }
            />
          )}
          {options.showEmail && (
            <Input
              label={`Co-Lessee ${i + 1} Email`}
              name={coLesseeEmail}
              onChange={onCoLesseeChange}
              value={coLesseesData[coLesseeEmail] || ''}
              type='email'
              required
              disabled={disabled}
              fullBorderStyles={{ width: '100%', maxWidth: 490 }}
              append={
                IS_ADMIN &&
                i + 1 === coLesseesRenderData.length &&
                !disabled &&
                !options.showEmail && (
                  <Button
                    style={{ margin: '0 10px' }}
                    buttonClass='button-danger'
                    onClick={() => onCoLesseeDelete(i + 1)}
                    disabled={disabled}
                  >
                    X
                  </Button>
                )
              }
            />
          )}
        </div>
      )
    })

  return (
    <div className='co-lessee-box'>
      {renderCoLessees()}
      {!disabled && coLesseesRenderData.length !== 3 && !options.showEmail && (
        <Button
          className='button add-co-lessee-button'
          disabled={disabled}
          onClick={() =>
            setCoLesseesRenderData([...coLesseesRenderData, 'co-lessee'])
          }
        >
          Add Co-Lessee
        </Button>
      )}
    </div>
  )
}

CoLessees.propTypes = {
  mainLeaseInputHandler: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  IS_ADMIN: PropTypes.bool,
}

export default CoLessees

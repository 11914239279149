import React, { useState, useEffect } from 'react'
import { Map, Overlay, Marker } from 'rgm'
import { ClipLoader } from 'react-spinners'

// use https://www.latlong.net/ for coords, google maps is jank
const MAP_OPTIONS = {
  zoom: 9,
  center: {
    lat: 43.576676,
    lng: -79.776551,
  },
  markham: {
    lat: 43.84378,
    lng: -79.36432,
  },
  burlington: {
    lat: 43.368593,
    lng: -79.792066,
  },
  gestureHandling: 'greedy',
  clickableIcons: true,
}

const invokeMapApi = async () => {
  // Create the script tag, set the appropriate attributes
  const script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&callback=mapLoaded`
  script.defer = true
  script.async = true

  // Append the 'script' element to 'head'
  document.head.appendChild(script)
}

const CircleMarker = () => <div className='google-maps-pin' />

const GoogleMap = () => {
  const [mapsAPI, setMapsAPI] = useState(null)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.mapLoaded = () => {
        if (window?.google?.maps) {
          setMapsAPI(window.google.maps)
        }
      }

      // invoke if first load
      if (!window?.google?.maps) {
        invokeMapApi()
      } else {
        setMapsAPI(window.google.maps)
      }
    }
  }, [])

  return (
    <div className='google-map-wrapper'>
      <div className='map-container'>
        <div className='google-map'>
          <h2 className='map-title'>
            <u>Office Locations</u>
          </h2>
          {!mapsAPI ? (
            <div className='col-12 mt-10 d-flex justify-content-center'>
              <ClipLoader loading size='60px' color='#3b9453' />
            </div>
          ) : (
            <Map api={mapsAPI} options={MAP_OPTIONS}>
              <Overlay>
                <Marker
                  lat={MAP_OPTIONS.markham.lat}
                  lng={MAP_OPTIONS.markham.lng}
                >
                  <CircleMarker name='Markham' />
                </Marker>
                <Marker
                  lat={MAP_OPTIONS.burlington.lat}
                  lng={MAP_OPTIONS.burlington.lng}
                >
                  <CircleMarker name='Burlington' />
                </Marker>
              </Overlay>
            </Map>
          )}
        </div>
      </div>
      <div className='location-info'>
        <div className='location-info-details'>
          <b>Markham - Head Office</b>
          <div>500 Cochrane Dr. Unit 2</div>
          <div>
            Markham, Ontario. L3R 8E2 (
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.google.ca/maps/dir//2-500+Cochrane+Dr,+Markham,+ON+L3R+8E2/@43.84366,-79.3664324,17z/data=!4m17!1m7!3m6!1s0x89d4d4b9245223b3:0x877e8ef1897a1d26!2s2-500+Cochrane+Dr,+Markham,+ON+L3R+8E2!3b1!8m2!3d43.84366!4d-79.3642437!4m8!1m0!1m5!1m1!1s0x89d4d4b9245223b3:0x877e8ef1897a1d26!2m2!1d-79.3642437!2d43.84366!3e2'
            >
              Directions
            </a>
            )
          </div>
          <div>Tel: 905-940-2151</div>
          <div>Fax: 905-940-1434</div>
        </div>
        <div className='location-info-details'>
          <b>Burlington</b>
          <div>1100 Walkers Line. Suite 304</div>
          <div>
            Burlington, Ontario. L7N 2G3 (
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.google.ca/maps/dir//1100+Walkers+Line+%23304,+Burlington,+ON+L7N+2G3/@43.368655,-79.8333283,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x882b61dcba8e490b:0xa75562962cccbaae!2m2!1d-79.7921288!2d43.3686!3e2?entry=ttu'
            >
              Directions
            </a>
            )
          </div>
          <div>Tel: 905-631-8001</div>
          <div>Fax: 905-632-8823</div>
        </div>
      </div>
    </div>
  )
}

export default GoogleMap

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Menu from './Menu'
import Hamburger from './Hamburger'
import logoShort from '../images/logo-short.png'
import logoFull from '../images/logo-full.png'
import MenuMobile from './MenuMobile'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuActive: false,
    }
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      menuActive: !prevState.menuActive,
    }))
  }

  renderLogo = () =>
    this.props.location?.pathname === '/' ? (
      <img
        alt='ADD Capital HomePage'
        src={logoFull}
        style={{ width: '400px', marginTop: '16px' }}
      />
    ) : (
      <img
        alt='ADD Capital Home Page'
        src={logoShort}
        style={{ width: '160px', marginTop: '25px' }}
      />
    )

  render() {
    return (
      <div className='header'>
        <div className='container'>
          <div className='logo'>
            <Link to='/'>{this.renderLogo()}</Link>
          </div>
          <div className='logo-mobile'>
            <Link to='/'>
              <img alt='ADD Capital Home Page' src={logoShort} />
            </Link>
          </div>
          <MenuMobile active={this.state.menuActive} />
          <Menu />
          <Hamburger toggleMenu={this.toggleMenu} />
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  location: PropTypes.object,
}

export default Header

/* eslint-disable react/function-component-definition */
import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-dropdown-select'

const BrokerDropdown = ({
  IS_ADMIN,
  brokerList = [],
  selectedBrokerFilter = [],
  onDropdownChange,
  placeholder = 'Select a Broker Filter...',
}) => {
  return IS_ADMIN ? (
    <Select
      options={brokerList}
      onChange={onDropdownChange}
      values={selectedBrokerFilter}
      placeholder={placeholder}
      className='broker-dropdown-control-styles broker-dropdown-menu-styles'
    />
  ) : null
}

BrokerDropdown.propTypes = {
  IS_ADMIN: PropTypes.bool,
  brokerList: PropTypes.array,
  onDropdownChange: PropTypes.func,
  selectedBrokerFilter: PropTypes.array,
  placeholder: PropTypes.string,
}

export default BrokerDropdown

import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import SEO from '../../components/SEO'
import Layout from '../../layouts/index'

const About = props => {
  const aboutInfo = props.data.allMarkdownRemark.edges.reduce(
    (acc, aboutSection) => {
      return {
        ...acc,
        [aboutSection.node.frontmatter.name]: {
          ...aboutSection.node.frontmatter,
          body: aboutSection.node.excerpt,
          html: aboutSection.node.html,
        },
      }
    },
    {},
  )

  return (
    <Layout bodyClass='gradient'>
      <SEO title='About' />
      <div className='page-banner about-background'>
        <div className='page-title'>About Us</div>
      </div>

      <div className='page-section-1'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-1 about-container'>
              <div className='about'>
                <img
                  alt='Highway Truck'
                  src={aboutInfo.history.image}
                  className='about-image'
                />
              </div>
              <div className='about'>
                <div className='about-meta'>
                  <h2 className='about-title'>{aboutInfo.history.title}</h2>
                  <hr />
                </div>
                <hr />
                <div
                  className='about-content'
                  dangerouslySetInnerHTML={{ __html: aboutInfo.history.html }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='page-section-2'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-1 about-container mobile-mission'>
              <div className='about'>
                <div className='about-meta'>
                  <h2 className='about-title'>{aboutInfo.mission.title}</h2>
                  <hr />
                </div>
                <hr />
                <div
                  className='about-content'
                  dangerouslySetInnerHTML={{ __html: aboutInfo.mission.html }}
                />
              </div>
              <div className='about'>
                <img
                  alt='Close Up Tractor'
                  src={aboutInfo.mission.image}
                  className='about-image'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='page-section-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mb-1 about-container mobile-mission'>
              <div className='about'>
                <img
                  alt='Tall Tractor'
                  src={aboutInfo.services.image}
                  className='about-image'
                  style={{ width: '90%' }}
                />
              </div>
              <div className='about'>
                <div className='about-meta'>
                  <h2 className='about-title'>{aboutInfo.services.title}</h2>
                  <hr />
                </div>
                <hr />
                <div
                  className='about-content'
                  dangerouslySetInnerHTML={{ __html: aboutInfo.services.html }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

About.propTypes = {
  data: PropTypes.object,
}

export const query = graphql`
  query AboutQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/about/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 1000)
          frontmatter {
            path
            name
            title
            image
          }
        }
      }
    }
  }
`

export default About

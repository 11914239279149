import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const PrivateRoute = ({
  component: Component,
  location,
  isLoggedIn,
  ...rest
}) => {
  if (!isLoggedIn && location.pathname !== '/portal/login') {
    navigate('/portal/login')
    return null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
}

export default PrivateRoute

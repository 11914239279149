import React from 'react'
import propTypes from 'prop-types'

const LeaseToggle = ({ leaseToggleState, setLeaseToggleState }) => {
  return (
    <div className='selectorButtons'>
      <div
        className={`selectorButton ${
          leaseToggleState === 'credit' && 'selected'
        }`}
        onClick={() => setLeaseToggleState('credit')}
        id='credit'
      >
        Credit Application
      </div>
      <div
        className={`selectorButton ${
          leaseToggleState === 'contract' && 'selected'
        }`}
        onClick={() => setLeaseToggleState('contract')}
        id='contract'
      >
        Lease Documentation
      </div>
    </div>
  )
}

LeaseToggle.propTypes = {
  leaseToggleState: propTypes.string,
  setLeaseToggleState: propTypes.func,
}

export default LeaseToggle

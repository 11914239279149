import React, { useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

import { Auth, API } from '../amplify'

import Text from './Text'
import Input from './Input'
import Button from './Button'

const RegisterForm = () => {
  const [registerCredentials, setRegisterCredentials] = useState({
    name: '',
    username: '', // actually an email, just named username
    phone_number: '',
    password: '',
    confirm_password: '',
    authCode: '',
  })
  const [registerStep, setRegisterStep] = useState(0)
  const [registerStatus, setRegisterStatus] = useState({
    message: '',
    error: false,
    loading: false,
  })
  const [initialRegisterResponse, setInitialRegisterResponse] = useState({})

  const ADMIN_USERS = JSON.parse(process.env.GATSBY_ADMIN_USERS)
  const getUserType = email =>
    ADMIN_USERS.includes(email.toLowerCase()) ? 'admin' : 'broker'

  const onRegisterInputChange = e =>
    setRegisterCredentials({
      ...registerCredentials,
      [e.target.name]: e.target.value,
    })

  const registerUser = async e => {
    e.preventDefault()
    setRegisterStatus({
      message: '',
      error: false,
      loading: false,
    })

    const { name, username, phone_number, password, confirm_password } =
      registerCredentials

    // if password is too short
    if (password.length < 8) {
      setRegisterStatus({
        message: 'Your password must be at least 8 characters long.',
        error: true,
        loading: false,
      })
      return
    }

    // if passwords do not match
    if (password !== confirm_password) {
      setRegisterStatus({
        message: 'Your passwords do not match.',
        error: true,
        loading: false,
      })
      return
    }

    // format phone number
    const formatted_phone_number = `+1${phone_number.replace(
      /^\+|\(|\)|-| /g,
      '',
    )}`
    if (formatted_phone_number.length !== 12) {
      setRegisterStatus({
        message: 'Your phone number is not 10 digits long',
        error: true,
        loading: false,
      })
      return
    }

    setRegisterStatus({
      ...registerStatus,
      loading: true,
    })

    const formattedRegisterCredentials = {
      username: username.toLowerCase(),
      password,
      attributes: {
        name,
        phone_number: formatted_phone_number,
        'custom:type': getUserType(username),
      },
    }

    const registerResponse = await Auth.register(formattedRegisterCredentials)
    // think about persisting this somewhere if we add re-verify functionality
    setInitialRegisterResponse(registerResponse)

    // if initial registration was successful and we want to confirm SMS
    if (registerResponse?.user && !registerResponse?.userConfirmed) {
      setRegisterStatus({
        message: `Please enter the SMS code sent to ${registerResponse.codeDeliveryDetails.Destination}`,
        error: false,
        loading: false,
      })
      setRegisterStep(1)

      // if some other error happens
    } else {
      setRegisterStatus({
        message:
          registerResponse.message ||
          'An unknown error has occured. Please try again!',
        error: true,
        loading: false,
      })
    }
  }

  const verifyRegister = async e => {
    e.preventDefault()
    setRegisterStatus({
      message: '',
      error: false,
      loading: true,
    })

    const verifyRegisterResponse = await Auth.verifyRegister(
      registerCredentials.username.toLowerCase(),
      registerCredentials.authCode,
    )

    // if the verification code is incorrect
    if (verifyRegisterResponse.code === 'CodeMismatchException') {
      setRegisterStatus({
        message: verifyRegisterResponse?.message,
        error: true,
        loading: false,
      })
    } else if (verifyRegisterResponse === 'SUCCESS') {
      // add newly created user to users table
      await API.createUser({
        id: initialRegisterResponse.userSub,
        name: registerCredentials.name,
        email: registerCredentials.username.toLowerCase(),
        phone: registerCredentials.phone_number.replace(/^\+|\(|\)|-| /g, ''),
        type: getUserType(registerCredentials.username),
      })

      setRegisterStatus({
        message: 'Successfully verified your account. You may now log in.',
        error: false,
        loading: false,
      })
      setRegisterCredentials({
        name: '',
        username: '',
        phone_number: '',
        password: '',
        confirm_password: '',
        authCode: '',
      })
      setRegisterStep(2)
    } else {
      setRegisterStatus({
        message:
          verifyRegisterResponse?.message ||
          'An unknown error has occured. Please try again!',
        error: true,
        loading: false,
      })
    }
  }

  return (
    <div>
      {/* Initial Register */}
      {registerStep === 0 && (
        <form onSubmit={registerUser}>
          <div>
            <Text error={registerStatus.error} style={{ fontStyle: 'italic' }}>
              {registerStatus.message}
            </Text>
          </div>
          <Input
            name='name'
            type='text'
            placeholder='Enter Full Name'
            prepend='&#128203;'
            onChange={onRegisterInputChange}
            required
            disabled={registerStatus.loading}
            value={registerCredentials.name}
          />
          <Input
            name='username'
            type='email'
            placeholder='Enter Email'
            prepend='&#128231;'
            onChange={onRegisterInputChange}
            required
            disabled={registerStatus.loading}
            value={registerCredentials.username}
          />
          <div style={{ fontSize: 10, color: 'red' }}>
            ATTENTION: You will be required to confirm your registration via
            text message using this cell phone number
          </div>
          <Input
            name='phone_number'
            type='tel'
            placeholder='Enter Cell Phone Number'
            prepend='&#128222;'
            onChange={onRegisterInputChange}
            required
            disabled={registerStatus.loading}
            value={registerCredentials.phone_number}
          />
          <Input
            name='password'
            type='password'
            placeholder='Enter Password'
            prepend='&#128274;'
            onChange={onRegisterInputChange}
            required
            disabled={registerStatus.loading}
            value={registerCredentials.password}
          />
          <Input
            name='confirm_password'
            type='password'
            placeholder='Enter Password Again'
            prepend='&#128272;'
            onChange={onRegisterInputChange}
            required
            disabled={registerStatus.loading}
            value={registerCredentials.confirm_password}
          />
          {registerStatus.loading ? (
            <ClipLoader loading size='35px' color='#3b9453' />
          ) : (
            <Button type='submit'>Register</Button>
          )}
        </form>
      )}

      {/* Register SMS Confirmation */}
      {registerStep === 1 && (
        <form onSubmit={verifyRegister}>
          <Text error={registerStatus.error} style={{ fontStyle: 'italic' }}>
            {registerStatus.message}
          </Text>
          <Input
            name='authCode'
            type='text'
            placeholder='Enter SMS Confirmation Code'
            onChange={onRegisterInputChange}
          />
          {registerStatus.loading ? (
            <ClipLoader loading size='35px' color='#3b9453' />
          ) : (
            <Button type='submit'>Verify Registration</Button>
          )}
        </form>
      )}

      {registerStep === 2 && (
        <>
          <Text
            error={registerStatus.error}
            style={{ fontStyle: 'italic', margin: '15px 0' }}
          >
            {registerStatus.message}
          </Text>
          <Button
            style={{ margin: '10px 0' }}
            onClick={() => navigate('/portal')}
          >
            Go to Login
          </Button>
        </>
      )}
    </div>
  )
}

RegisterForm.propTypes = {}

export default RegisterForm

// KEEP IN SYNC WITH - amplify/backend/function/currencyWebhooks/src/actions.js

export const CURRENCY_ACTIONS = {
  GET_APPLICATION: 'GET_APPLICATION',
  UPDATE_APPLICATION: 'UPDATE_APPLICATION',
  GET_SECTION1071: 'GET_SECTION1071',
  GET_STIPULATION: 'GET_STIPULATION',
  CREATE_OR_UPDATE_STIPULATION: 'CREATE_OR_UPDATE_STIPULATION',
  GET_STIPULATION_TYPES: 'GET_STIPULATION_TYPES',
  GET_DOCUMENT: 'GET_DOCUMENT',
  CREATE_WEBHOOK: 'CREATE_WEBHOOK',
  DELETE_WEBHOOK: 'DELETE_WEBHOOK',
}

// keep in sync with amplify/backend/function/currencyWebhooks/src/Lease/LeaseHelpers.js
export const CURRENCY_CC_EMAILS = ['brad.m@addcapital.ca']

// currency dev+prod broker email: credit+currency@addcapital.ca
// currency broker password: AddCapitalCurrency2025
// if this changes, also change the broker id in the currency webhook lambda
// amplify/backend/function/currencyWebhooks/src/Lease/LeaseController.js
const DEV_CURRENCY_BROKER_ID = '5bc1abc8-9a09-4e24-ab0e-385e67c6e761'
const PROD_CURRENCY_BROKER_ID = 'ab7a17e4-ef18-446a-aeb1-e94b18e86a1b'

export const CURRENCY_BROKER_ID =
  process.env.GATSBY_ADD_ENV === 'production'
    ? PROD_CURRENCY_BROKER_ID
    : DEV_CURRENCY_BROKER_ID

export const CURRENCY_DECLINE_REASONS = [
  {
    label: 'Insufficient Credit Score',
    value: 1,
  },
  {
    label: 'Poor Credit History',
    value: 2,
  },
  {
    label: 'Thin Credit History',
    value: 3,
  },
  {
    label: 'Restricted EQ/Industry/State',
    value: 4,
  },
  {
    label: 'FICO Score',
    value: 5,
  },
  {
    label: 'Insufficient TIB',
    value: 6,
  },
  {
    label: 'Insufficient Payback Capacity',
    value: 7,
  },
  {
    label: 'Low Comp Credit',
    value: 8,
  },
  {
    label: 'Non-Essential Equipment',
    value: 9,
  },
  {
    label: 'Delinquencies/Charge Offs',
    value: 10,
  },
  {
    label: 'Paynet Score',
    value: 11,
  },
  {
    label: 'High Utilization',
    value: 12,
  },
  {
    label: 'Bankruptcy',
    value: 13,
  },
  {
    label: 'Child Support',
    value: 14,
  },
  {
    label: 'Outstanding Approval w/ other Broker',
    value: 15,
  },
  {
    label: 'Potential Fraud',
    value: 16,
  },
  {
    label: 'Other',
    value: 17,
  },
]

import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const LeaseSquare = ({ status, location, leases }) => {
  const leaseCount = leases
    ? leases.items.length >= 1000
      ? '1000+'
      : leases.items.length
    : '-'

  return (
    <div
      className='lease-square'
      onClick={() => navigate(`/portal/leases/${location}`)}
    >
      <div className='lease-square-status'>{status}</div>
      <div className='lease-square-count'>{leaseCount}</div>
    </div>
  )
}

LeaseSquare.propTypes = {
  status: PropTypes.string,
  location: PropTypes.string,
  leases: PropTypes.object,
}

export default LeaseSquare

import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import SEO from '../../components/SEO'
import Layout from '../../layouts/index'
import customerService from '../../images/finance/service.svg'
import competitiveRates from '../../images/finance/rates.svg'
import customizedPackages from '../../images/finance/customized.svg'
import oneStopShop from '../../images/finance/one-stop-shop.svg'
import checkMark from '../../images/check-mark.svg'

const Finance = props => {
  const financeInfo = props.data.allMarkdownRemark.edges.reduce(
    (acc, financeSection) => {
      return {
        ...acc,
        [financeSection.node.frontmatter.name]: {
          ...financeSection.node.frontmatter,
          body: financeSection.node.excerpt,
          html: financeSection.node.html,
        },
      }
    },
    {},
  )

  return (
    <Layout bodyClass='gradient'>
      <SEO title='Finance' />
      <div className='page-banner finance-background'>
        <div className='page-title'>Lease With Us</div>
      </div>

      <div className='page-section-1'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='finance-title text-center'>
                {financeInfo.whyUs.title}
              </h1>
              <hr />
            </div>
            <div className='col-12 finance-showcase'>
              <div className='showcase-item'>
                <img alt='Competive Rates' src={competitiveRates} />
                <p>Competitive Rates</p>
              </div>
              <div className='showcase-item'>
                <img alt='Personal Service' src={customerService} />
                <p>Personal Service</p>
              </div>
              <div className='showcase-item'>
                <img alt='Customized Packages' src={customizedPackages} />
                <p>Customized Packages</p>
              </div>
              <div className='showcase-item'>
                <img alt='One Stop Shop' src={oneStopShop} />
                <p>One Stop Shop</p>
              </div>
            </div>
            <div
              className='finance-text p-1 text-center'
              dangerouslySetInnerHTML={{ __html: financeInfo.whyUs.html }}
            />
          </div>
        </div>
      </div>
      <div className='page-section-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='finance-title text-center'>
                {financeInfo.benefits.title}
              </h1>
              <hr />
            </div>
            <div className='col-12 benefits-container mb-10'>
              <div className='finance-benefit'>
                <div className='col-12'>
                  <img alt='Check' src={checkMark} />
                </div>
                {financeInfo.cashFlow.title}
                <div className='benefit-cover'>{financeInfo.cashFlow.body}</div>
              </div>
              <div className='finance-benefit'>
                <div className='col-12'>
                  <img alt='Check' src={checkMark} />
                </div>
                {financeInfo.taxBenefits.title}
                <div className='benefit-cover'>
                  {financeInfo.taxBenefits.body}
                </div>
              </div>
              <div className='finance-benefit'>
                <div className='col-12'>
                  <img alt='Check' src={checkMark} />
                </div>
                {financeInfo.assetManagement.title}
                <div className='benefit-cover'>
                  {financeInfo.assetManagement.body}
                </div>
              </div>
              <div className='finance-benefit'>
                <div className='col-12'>
                  <img alt='Check' src={checkMark} />
                </div>
                {financeInfo.balanceSheet.title}
                <div className='benefit-cover'>
                  {financeInfo.balanceSheet.body}
                </div>
              </div>
            </div>
            <div className='finance-text p-1 col-12 text-center'>
              {financeInfo.benefits.body}
            </div>
            <div className='text-center col-12'>
              <Link className='button button-clear mt-2' to='/contact'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query financeQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/lease-with-us/" } }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 1000)
          frontmatter {
            name
            title
            path
          }
        }
      }
    }
  }
`

Finance.propTypes = {
  data: PropTypes.object,
}

export default Finance

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'
import { navigate } from 'gatsby'

import { Auth } from '../amplify'

import Text from './Text'
import Input from './Input'
import Button from './Button'
import ResetPassword from './ResetPassword'

const LoginForm = ({ refreshAuthStatus, isLoggedIn }) => {
  const [loginCredentials, setLoginCredentials] = useState({
    username: '', // actually an email, just named username
    password: '',
  })
  const [loginStatus, setLoginStatus] = useState({
    message: '',
    error: false,
    loading: false,
    successful: false,
    forgotPassword: false,
  })

  // if user is already authenticated
  if (isLoggedIn) {
    navigate('/portal')
  }

  const onLoginInputChange = e =>
    setLoginCredentials({
      ...loginCredentials,
      [e.target.name]: e.target.value,
    })

  const loginUser = async e => {
    e.preventDefault()

    setLoginStatus({
      ...loginStatus,
      loading: true,
    })

    const loginResponse = await Auth.login(
      loginCredentials.username.toLowerCase(),
      loginCredentials.password,
    )

    // if initial registration was successful and we want to confirm SMS
    if (loginResponse?.username) {
      setLoginStatus({
        message: 'You have been successfully logged in.',
        error: false,
        loading: false,
        successful: true,
      })
      setLoginCredentials({
        username: '',
        password: '',
      })
      setTimeout(() => {
        refreshAuthStatus()
        navigate('/portal')
      }, 2000)
    } else {
      // if some other error happens
      setLoginStatus({
        message:
          loginResponse?.message ||
          'An unknown error has occured. Please try again!',
        error: true,
        loading: false,
        successful: false,
      })
    }
  }

  return (
    <>
      {loginStatus.forgotPassword ? (
        <ResetPassword refreshAuthStatus={refreshAuthStatus} />
      ) : (
        <div className='auth col-12'>
          <div className='auth-box-top'>
            <div className='col-12 intro intro-small'>
              <h1>Broker Login</h1>
            </div>
            <form onSubmit={loginUser}>
              <Text
                error={loginStatus.error}
                style={{ fontStyle: 'italic', padding: '5px 0 10px 0' }}
              >
                {loginStatus.message}
              </Text>
              {!loginStatus.successful && (
                <>
                  <Input
                    name='username'
                    type='email'
                    placeholder='Enter Email'
                    prepend='&#128231;'
                    onChange={onLoginInputChange}
                    required
                    disabled={loginStatus.loading}
                    value={loginCredentials.username}
                  />
                  <Input
                    name='password'
                    type='password'
                    placeholder='Enter Password'
                    prepend='&#128274;'
                    onChange={onLoginInputChange}
                    required
                    disabled={loginStatus.loading}
                    value={loginCredentials.password}
                  />
                  <>
                    {loginStatus.loading ? (
                      <ClipLoader loading size='35px' color='#3b9453' />
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <Button type='submit'>Login</Button>
                      </div>
                    )}
                  </>
                </>
              )}
            </form>
            <Button
              buttonClass='button-danger mt-3'
              onClick={() => setLoginStatus({ forgotPassword: true })}
            >
              Forgot your password?
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

LoginForm.propTypes = {
  refreshAuthStatus: PropTypes.func,
  isLoggedIn: PropTypes.bool,
}

export default LoginForm

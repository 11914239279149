import React from 'react'

class NotFound extends React.Component {
  render() {
    return (
      <>
        <h1>Not Found</h1>
        <p>You just hit a page that doesn&#39;t exist...</p>
      </>
    )
  }
}

export default NotFound

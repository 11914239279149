import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'

import * as LEASE_STATUS from '../data/leaseStatus'

const LeaseMainInfo = ({ data }) => {
  return (
    <div className='info-block'>
      <b>Lease Information</b>
      <div>{`Status - ${LEASE_STATUS[data.lease.status].display}`}</div>
      <div>
        {`Submitted - ${moment(data.lease.createdAt).format(
          'MMMM DD, YYYY h:mm A',
        )}`}
      </div>
      <div>{`Submitted By - ${data.broker.name}`}</div>
      {data.lease?.reference_number ? (
        <div>{`Reference Number: ${data.lease.reference_number}`}</div>
      ) : null}
    </div>
  )
}

LeaseMainInfo.propTypes = {
  data: propTypes.object,
}

export default LeaseMainInfo

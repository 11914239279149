import React from 'react'
import propTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'
import Select from 'react-dropdown-select'

import * as LEASE_STATUS from '../data/leaseStatus'
import { CURRENCY_DECLINE_REASONS } from '../util/currency/constants'

import Input from './Input'
import Button from './Button'

const LeaseAdminReview = ({
  adminReview,
  setAdminReview,
  submitAdminReview,
  formType,
  isFromCurrency = false,
}) => {
  const showFeedbackTextArea = !isFromCurrency || adminReview.status === LEASE_STATUS.APPROVE_CREDIT
  // For currency leases, decline feedback must be from predefined options
  const showFeedbackDeclineDropdown = isFromCurrency && adminReview.status === LEASE_STATUS.DENY_CREDIT

  const onFeedbackChange = (option) => {
    setAdminReview({
      ...adminReview,
      feedback: option.length ? option[0].label : '',
    })
  }

  return (
    // Credit Form
    <div className='admin-credit-review'>
      {!adminReview.successful && (
        <>
          <b>{`Admin ${formType} Review`}</b>
        </>
      )}

      {!adminReview.successful ? (
        formType === 'Credit' ? (
          <form onSubmit={submitAdminReview}>
            <Input
              type='radio'
              label='Approve Credit'
              checked={adminReview.status === LEASE_STATUS.APPROVE_CREDIT}
              onChange={() =>
                setAdminReview({
                  ...adminReview,
                  status: LEASE_STATUS.APPROVE_CREDIT,
                })
              }
              name='radio-approve'
              inputStyles={{ width: 20, height: 20 }}
            />
            <Input
              type='radio'
              label='Deny Credit'
              checked={adminReview.status === LEASE_STATUS.DENY_CREDIT}
              onChange={() =>
                setAdminReview({
                  ...adminReview,
                  status: LEASE_STATUS.DENY_CREDIT,
                })
              }
              name='radio-deny'
              inputStyles={{ width: 20, height: 20 }}
            />
            <Input
              type='radio'
              label='Needs Credit Information'
              checked={adminReview.status === LEASE_STATUS.NEEDS_INFO_CREDIT}
              onChange={() =>
                setAdminReview({
                  ...adminReview,
                  status: LEASE_STATUS.NEEDS_INFO_CREDIT,
                })
              }
              name='radio-needs-info'
              inputStyles={{ width: 20, height: 20 }}
            />
            {showFeedbackTextArea && (
              <Input
                textarea
                placeholder='Enter any feedback for the broker'
                onChange={e =>
                  setAdminReview({
                    ...adminReview,
                    feedback: e.target.value,
                  })
                }
              />
            )}
            {showFeedbackDeclineDropdown && (
              <Select
                options={CURRENCY_DECLINE_REASONS}
                onChange={onFeedbackChange}
                values={adminReview.feedback ? [adminReview.feedback] : []}
                placeholder='Select a reason for declining credit application'
                style={{ marginBottom: 30, cursor: 'pointer' }}
                dropdownHeight='125px'
              />
            )}
            {adminReview.loading ? (
              <div className='col-12 mt-10 d-flex justify-content-center'>
                <ClipLoader loading size='40px' color='#3b9453' />
              </div>
            ) : (
              <Button type='submit' disabled={!adminReview.status}>
                Submit Credit Review
              </Button>
            )}
          </form>
        ) : (
          // Contract Form
          <form onSubmit={submitAdminReview}>
            <Input
              type='radio'
              label='Approve Contract'
              checked={adminReview.status === LEASE_STATUS.APPROVE_CONTRACT}
              onChange={() =>
                setAdminReview({
                  ...adminReview,
                  status: LEASE_STATUS.APPROVE_CONTRACT,
                })
              }
              name='radio-approve'
              inputStyles={{ width: 20, height: 20 }}
            />

            <Input
              type='radio'
              label='Needs Contract Information'
              checked={adminReview.status === LEASE_STATUS.NEEDS_INFO_CONTRACT}
              onChange={() =>
                setAdminReview({
                  ...adminReview,
                  status: LEASE_STATUS.NEEDS_INFO_CONTRACT,
                })
              }
              name='radio-needs-info'
              inputStyles={{ width: 20, height: 20 }}
            />
            <Input
              textarea
              placeholder='Enter any feedback for the broker'
              onChange={e =>
                setAdminReview({
                  ...adminReview,
                  feedback: e.target.value,
                })
              }
            />
            {adminReview.loading ? (
              <div className='col-12 mt-10 d-flex justify-content-center'>
                <ClipLoader loading size='40px' color='#3b9453' />
              </div>
            ) : (
              <Button type='submit' disabled={!adminReview.status}>
                Submit Contract Review
              </Button>
            )}
          </form>
        )
      ) : null}
    </div>
  )
}

LeaseAdminReview.propTypes = {
  adminReview: propTypes.object,
  setAdminReview: propTypes.func,
  submitAdminReview: propTypes.func,
  formType: propTypes.string,
  isFromCurrency: propTypes.bool,
}

export default LeaseAdminReview

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import axios from 'axios'
import qs from 'qs'
import { ClipLoader } from 'react-spinners'
import { v4 as uuid } from 'uuid'

import { API, Lambda, Util } from '../amplify'

import Input from './Input'
import Button from './Button'
import Text from './Text'

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '17px',
      color: '#495057',
      fontFamily:
        '"Open Sans", "Helvetica Neue", Arial, sans-serif, -apple-system',
      letterSpacing: '0.025em',
      lineHeight: '40px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#c23d4b',
    },
  },
}

const AdminFeeInfo = () => (
  <span
    className='admin-info-tooltip'
    data-toggle='tooltip'
    data-tooltip='An administration fee of 3.25% has been charged in order for us to process this payment accordingly.'
    data-placement='top'
    role='img'
    aria-label='Admin Fee Info'
  >
    &nbsp;&nbsp;&nbsp;&#10067;
  </span>
)

const StripeForm = ({ setField, paymentInfo }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [isCreditReady, setIsCreditReady] = useState(false)
  const [formStatus, setFormStatus] = useState({
    message: null,
    error: false,
    loading: false,
  })

  const onCardChange = event => {
    setIsCreditReady(event.complete)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }
    setFormStatus({
      message: null,
      error: false,
      loading: true,
    })

    try {
      const floatSafePaymentAmt = Math.floor(paymentInfo.totalAmount * 100)

      // TODO: Move this to lambda if we really want this to be proper
      const paymentIntent = (
        await axios.post(
          'https://api.stripe.com/v1/payment_intents',
          qs.stringify({
            amount: floatSafePaymentAmt, // stripe deals in cents
            currency: 'cad', // will we only have to deal with CAD?
            payment_method_types: ['card'],
          }),
          {
            headers: {
              Authorization: `Bearer ${process.env.GATSBY_STRIPE_SECRET_API_KEY}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          },
        )
      )?.data

      const result = await stripe.confirmCardPayment(
        paymentIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: paymentInfo.name,
              email: paymentInfo.email,
            },
          },
        },
      )

      if (result.error) {
        setFormStatus({
          message: result.error.message,
          error: true,
          loading: false,
        })
      } else {
        // create payment record
        await API.createPayment({
          id: uuid(),
          clientName: paymentInfo.name,
          clientEmail: paymentInfo.email,
          businessName: paymentInfo.businessName,
          totalAmount: floatSafePaymentAmt,
          stripeID: result.paymentIntent.id,
        })

        // send emails after payment succeeds
        await Lambda.sendEmail({
          IS_NOTIFICATION: false,
          TO: paymentInfo.email,
          FROM: 'CREDIT',
          subject: 'ADD Capital Payment Receipt',
          body: `Thank you for your payment to ADD Capital. This is a confirmation email for you to keep.<br /><br />
          Paid by: ${paymentInfo.name}<br />
          Business: ${paymentInfo.businessName}<br />
          Total Amount Paid: $${paymentInfo.totalAmount?.toLocaleString(
            'en-US',
          )}<br />
          Paid on: ${new Date().toDateString()}`,
        })

        // send email to ADD too?

        setFormStatus({
          message:
            'Payment successfully submitted. You will receive an email receipt.',
          error: false,
          loading: false,
        })
        setField('SUCCESS')
        elements.getElement(CardElement).clear()
      }
    } catch (error) {
      Util.LogError('ERROR_STRIPE_PAYMENT', error, paymentInfo)

      // if payment succeeds but GQL fails
      if (error?.errors) {
        setFormStatus({
          message:
            'Payment successfully submitted. You will receive an email receipt.',
          error: false,
          loading: false,
        })
      } else {
        setFormStatus({
          message:
            error.message || 'An unknown error has occured. Please try again!',
          error: true,
          loading: false,
        })
      }
    }
  }

  return (
    <form className='stripe-form' onSubmit={handleSubmit}>
      <Text
        error={formStatus.error}
        center
        style={{ fontStyle: 'italic', paddingBottom: 10 }}
      >
        {formStatus.message}
      </Text>
      <Input
        name='name'
        type='text'
        prepend='&#128203;'
        placeholder='Enter Full Name'
        value={paymentInfo.name}
        onChange={setField}
        required
        disabled={false}
      />
      <Input
        name='email'
        type='email'
        prepend='&#128231;'
        placeholder='Enter Email'
        value={paymentInfo.email}
        onChange={setField}
        required
        disabled={false}
      />
      <Input
        name='businessName'
        type='text'
        prepend='&#129534;'
        placeholder='Enter Business Name'
        value={paymentInfo.businessName}
        onChange={setField}
        required
        disabled={false}
      />
      <Input
        name='amount'
        type='number'
        prepend='&#128178;'
        step='.001'
        label='Enter Payment Amount'
        value={paymentInfo.amount}
        onChange={setField}
        required
        disabled={false}
      />
      <Input
        name='adminFee'
        type='number'
        prepend='&#128178;'
        append={<AdminFeeInfo />}
        step='.001'
        label='Administrative Fee'
        value={paymentInfo.adminFee}
        required
        disabled
      />
      <Input
        inputStyles={{
          height: 40,
          width: '100%',
          border: '1px solid #ced4da',
          borderRadius: '0.25rem',
          paddingLeft: '10px',
        }}
        customComponent={
          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onCardChange} />
        }
      />
      {formStatus.loading ? (
        <div className='d-flex justify-content-center'>
          <ClipLoader loading size='40px' color='#3b9453' />
        </div>
      ) : (
        <Button
          disabled={
            !stripe || !isCreditReady || Object.keys(paymentInfo).length !== 6
          }
          type='submit'
        >
          Confirm Payment
        </Button>
      )}
    </form>
  )
}

StripeForm.propTypes = {
  setField: PropTypes.func,
  paymentInfo: PropTypes.object,
}

export default StripeForm

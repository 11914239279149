import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const PortalPage = ({ headerLinks, user, children }) => {
  const path = window.location.pathname
  const SHOW_BACK_BUTTON = path !== '/portal' && path !== '/portal/'

  return (
    <>
      {/* Header */}
      <div className='portal-header'>
        <h1 className='title mt-5'>Broker Portal</h1>
        <p>{user?.attributes?.name && `Welcome, ${user?.attributes?.name}`}</p>
      </div>
      {/* Admin Nav */}
      <div className='portal-header-links'>{headerLinks}</div>
      {/* Content */}
      <div className='row'>
        <div className='portal-container col-12 box my-4'>{children}</div>
      </div>
      {SHOW_BACK_BUTTON && (
        <div id='bottom-back-button-mobile'>
          <Link
            className='button-danger mb-3'
            to='/portal/'
            key='backToPortalLink'
          >
            Back to Applications
          </Link>
        </div>
      )}
    </>
  )
}

PortalPage.propTypes = {
  headerLinks: PropTypes.array,
  user: PropTypes.object,
  children: PropTypes.node,
}

export default PortalPage

import React from 'react'
import PropTypes from 'prop-types'

const Button = ({
  children,
  text,
  buttonClass = 'button-submission',
  type = 'button',
  onClick,
  ...rest
}) => (
  <button type={type} className={buttonClass} onClick={onClick} {...rest}>
    {children || text}
  </button>
)

Button.propTypes = {
  buttonClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
}

export default Button

/* eslint-disable no-await-in-loop */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { v4 as uuid } from 'uuid'
import { createToast } from '../util/reactHelpers'

import { API, Lambda, Util } from '../amplify'
import leaseCreditFormSchema from '../data/leaseCreditFormSchema.json'
import { LEASE_PENDING_CREDIT } from '../data/leaseStatus'

import PortalPage from './PortalPage'
import Button from './Button'
import LeaseForm from './LeaseForm'
import BrokerDropdown from './BrokerDropdown'

// Starting count for lease reference number
const START_REF_COUNT = 50000

const NewLease = ({ user, brokerList }) => {
  const IS_ADMIN = user.attributes?.['custom:type'] === 'admin'

  const [LEASE_ID, SET_LEASE_ID] = useState(uuid())
  const [leaseStatus, setLeaseStatus] = useState({
    message: '',
    error: false,
    loading: false,
  })
  const [submitSuccessful, setSubmitSuccessful] = useState(false)
  const [selectedBroker, setSelectedBroker] = useState([])

  const leaseMetaData = {
    id: LEASE_ID,
    type: 'lease',
    broker_id: IS_ADMIN ? selectedBroker[0]?.value : user?.attributes?.sub,
    status: LEASE_PENDING_CREDIT.status,
  }

  const onLeaseSubmit = async leaseInputs => {
    if (IS_ADMIN && selectedBroker.length === 0) {
      return createToast(
        'You must select a broker to assign this lease to',
        'error',
      )
    }

    setLeaseStatus({
      message: '',
      error: false,
      loading: true,
    })

    let paginationToken = null
    let totalLeaseCount = 0

    // poll with pagination token to get full lease count
    do {
      const {
        data: {
          listLeases: { nextToken, scannedCount },
        },
      } = await API.getTotalLeaseCounts({ nextToken: paginationToken })

      paginationToken = nextToken
      totalLeaseCount += scannedCount
    } while (paginationToken !== null)

    const fields = leaseCreditFormSchema.flat().reduce((acc, field) => {
      const value = leaseInputs[field.name]
      return { ...acc, [field.name]: value ?? '' }
    }, {})

    try {
      const lease = await API.createLease({
        ...leaseMetaData,
        ...fields,
        // stringify co-lessees
        co_lessees: JSON.stringify(fields?.co_lessees || {}),
        // save cased lessee name to search on
        cased_lessee_name: fields.main_lessee_name.toUpperCase(),

        // reference number
        reference_number: String(totalLeaseCount + START_REF_COUNT || ''),
      })

      // send broker email about credit application
      if (IS_ADMIN) {
        // should ADD be cc'd?
        await Lambda.sendEmail({
          IS_NOTIFICATION: true,
          TO: selectedBroker[0].email,
          FROM: 'CREDIT',
          name: selectedBroker[0].label,
          clientName: fields.main_lessee_name,
          leaseStatus:
            'has been submitted by an ADD Capital admin on your behalf',
          leaseNextSteps:
            'The credit submission is currently in the review stage. Once it has been approved, you can begin entering lease documentation details',
          leaseID: lease.data.createLease.id,
        })
      } else {
        // send ADD notification on submit
        await Lambda.sendEmail({
          IS_NOTIFICATION: true,
          TO: 'CREDIT',
          FROM: 'CREDIT',
          name: 'ADD Capital',
          clientName: fields.main_lessee_name,
          leaseStatus: 'has been submitted by the broker',
          leaseNextSteps: 'View the lease to provide feedback to the broker',
          leaseID: lease.data.createLease.id,
        })
      }

      setLeaseStatus({
        ...leaseStatus,
        loading: false,
      })
      setSubmitSuccessful(true)
    } catch (error) {
      Util.LogError('ERROR_CREATING_LEASE', error, fields)

      const msg = 'An unknown error has occured. Please try again!'
      createToast(msg, 'error')

      setLeaseStatus({
        message: error.message || msg,
        error: true,
        loading: false,
      })
    }
  }

  return (
    <PortalPage
      user={user}
      headerLinks={[
        <Link className='button-danger' to='/portal/' key='backToLeaseLink'>
          Back to Applications
        </Link>,
      ]}
    >
      {submitSuccessful ? (
        <div className='centeredContentPage'>
          <div className='mb-5 text-center'>
            Successfully Submitted Credit Application for Review.
          </div>
          <Button
            onClick={() => {
              SET_LEASE_ID(uuid())
              setSubmitSuccessful(false)
              setLeaseStatus({
                message: '',
                error: false,
                loading: false,
              })
            }}
          >
            Submit Another Credit Application
          </Button>
        </div>
      ) : (
        <div className='d-flex flex-column w-100 px-3 pb-3'>
          <div className='dashboard-title'>NEW CREDIT APPLICATION</div>
          <div className='w-50 my-3'>
            {IS_ADMIN && <b>Select a broker to assign this lease to:</b>}
            <BrokerDropdown
              key='brokerDropdown'
              IS_ADMIN={IS_ADMIN}
              placeholder='Select a broker'
              brokerList={brokerList}
              selectedBrokerFilter={selectedBroker}
              onDropdownChange={setSelectedBroker}
            />
          </div>
          <div className='d-flex justify-content-between mb-5'>
            <LeaseForm
              onLeaseSubmit={onLeaseSubmit}
              leaseStatus={leaseStatus}
              leaseMetaData={leaseMetaData}
            />
          </div>
        </div>
      )}
    </PortalPage>
  )
}

NewLease.propTypes = {
  user: PropTypes.object,
  brokerList: PropTypes.array,
}

export default NewLease

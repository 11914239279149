import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

import { renderForm } from '../util'
import { isMobile } from '../util/helpers'

import Text from './Text'
import Button from './Button'
import FileUpload from './FileUpload'

const LeaseForm = ({ onLeaseSubmit, leaseStatus, leaseMetaData }) => {
  const [leaseInputs, setLeaseInputs] = useState({
    main_lessee_country: 'Canada',
  })

  const IS_MOBILE = isMobile('sm')

  const onLeaseInputChange = e => {
    let key
    let value

    // redundant but maybe good to be explicit
    if (e.target.type === 'dropdown') {
      key = e.target.name
      value = e.target.value
    } else if (e.target.type === 'checkbox') {
      key = e.target.name
      value = e.target.checked
    } else {
      key = e.target.name
      value = e.target.value
    }

    setLeaseInputs({
      ...leaseInputs,
      [key]: value,
    })
  }

  const submitLease = e => {
    e.preventDefault()
    onLeaseSubmit(leaseInputs)
  }

  return (
    <div className='box w-100'>
      <div className='box-top'>
        <form onSubmit={submitLease}>
          <Text
            error={leaseStatus.error}
            style={{ margin: '10px 0', fontStyle: 'italic' }}
          >
            {leaseStatus.message}
          </Text>
          {renderForm({
            schema: 'creditGeneral',
            labelType: 'edit',
            IS_ADMIN: false,
            IS_MOBILE,
            inputProps: {
              disabled: leaseStatus.loading,
              onChange: onLeaseInputChange,
            },
          })}
          <div className='create-lease-info-block'>
            <div className='info-block d-flex align-items-center'>
              <i>
                ATTENTION: Information inputted may be edited by an ADD Capital
                admin at any point.
              </i>
            </div>
            <div>
              <b>Add Lease Credit Documents</b>
              <FileUpload leaseMetaData={leaseMetaData} type='credit' />
            </div>
          </div>
          {leaseStatus.loading ? (
            <ClipLoader loading size='35px' color='#3b9453' />
          ) : (
            <Button type='submit' style={{ width: '100%' }}>
              Submit Credit Application
            </Button>
          )}
        </form>
      </div>
    </div>
  )
}

LeaseForm.propTypes = {
  onLeaseSubmit: PropTypes.func,
  leaseStatus: PropTypes.object,
  leaseMetaData: PropTypes.object,
}

export default LeaseForm

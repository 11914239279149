import { Lambda } from '../../amplify'
import { DOCUSIGN_ACTIONS } from './DocusignActions'
import { splitMultiLineText, formatNumber, truncate } from '../helpers'

const devStagingTemplateMap = {
  0: '89898203-a148-4a98-888d-0a4c06c9758b', // only main lessee (1_signature_contract)
  1: '7aac13d3-47df-4bea-87fe-646feb8150ff', // main lessee + 1 co-lessee (2_signature_contract)
  2: 'a3293688-0eb6-4709-aaf7-ec0171ebf9a3', // main lessee + 2 co-lessees (3_signature_contract)
  3: 'a6483ce9-6943-445d-860c-e9701bdc9a7a', // main lessee + 3 co-lessees (4_signature_contract)
}
const prodTemplateMap = {
  0: 'e40931fe-56f8-4be4-863c-5bbb37a12579', // only main lessee (1_signature_contract)
  1: 'a2ec167c-cf33-433e-9f0c-609e711c8e3a', // main lessee + 1 co-lessee (2_signature_contract)
  2: '71aed48f-dffa-41b7-af37-fe58c8f8678d', // main lessee + 2 co-lessees (3_signature_contract)
  3: 'cdbda91e-2ba9-4b34-ab9f-6036c44d187a', // main lessee + 3 co-lessees (4_signature_contract)
}

const templateMap =
  process.env.GATSBY_ADD_ENV === 'production'
    ? prodTemplateMap
    : devStagingTemplateMap

const EQUIPMENT_MAX_LEN = 86
const SUPPLIER_MAX_LEN = 31

/**
 *
 * @param {} lease - main lease object
 */
const generateTabData = lease => {
  const parsedCoLessees = lease.co_lessees
  const numCoLessees = Array(Object.keys(parsedCoLessees).length / 2).fill(
    'co-lessee',
  )

  const coLesseeNames = numCoLessees.map((_, i) => {
    const coLesseeNumber = i + 1
    return {
      tabLabel: `co_lessee_name_${coLesseeNumber}`,
      value: parsedCoLessees[`co_lessee_name_${coLesseeNumber}`],
    }
  })

  let coLesseesCombined = lease.main_lessee_name
  Object.keys(lease.co_lessees).forEach(colessee => {
    if (colessee.includes('name')) {
      coLesseesCombined += ' & ' + lease.co_lessees[colessee]
    }
  })

  const assets = splitMultiLineText(lease.asset_description)
  const serialNumbers = splitMultiLineText(lease.serial_number, true)

  return [
    // Main Info
    {
      tabLabel: 'main_lessee_name',
      value: lease.main_lessee_name,
    },
    ...coLesseeNames,
    {
      tabLabel: 'main_lessee_address',
      value: lease.main_lessee_address,
    },
    {
      tabLabel: 'main_lessee_city',
      value: lease.main_lessee_city,
    },
    {
      tabLabel: 'main_lessee_province',
      value: lease.main_lessee_province,
    },
    {
      tabLabel: 'main_lessee_postal_code',
      value: lease.main_lessee_postal_code,
    },
    {
      tabLabel: 'main_lessee_phone',
      value: lease.main_lessee_phone || '',
    },
    {
      tabLabel: 'main_lessee_cell',
      value: lease.main_lessee_cell || '',
    },
    {
      tabLabel: 'main_lessee_email',
      value: lease.main_lessee_email,
    },
    {
      tabLabel: 'main_lessee_contact',
      value: lease.main_lessee_contact || '',
    },

    // Asset Description
    {
      tabLabel: 'asset_description_1',
      value: truncate(assets[0], EQUIPMENT_MAX_LEN),
    },
    {
      tabLabel: 'asset_description_2',
      value: truncate(assets[1], EQUIPMENT_MAX_LEN),
    },
    {
      tabLabel: 'asset_description_3',
      value: truncate(assets[2], EQUIPMENT_MAX_LEN),
    },
    {
      tabLabel: 'serial_number_1',
      value: serialNumbers[0] || '',
    },
    {
      tabLabel: 'serial_number_2',
      value: serialNumbers[1] || '',
    },
    {
      tabLabel: 'serial_number_3',
      value: serialNumbers[2] || '',
    },
    {
      tabLabel: 'equipment_location',
      value: lease.equipment_location || '',
    },

    // Other Info
    {
      tabLabel: 'sales_rep',
      value: lease.sales_rep || '',
    },
    {
      tabLabel: 'vendor_phone_number',
      value: lease.vendor_phone_number || '',
    },

    // Payment Terms 1
    {
      tabLabel: 'term_okd',
      value: String(lease.term_okd || ''),
    },
    {
      tabLabel: 'number_of_payments_1',
      value: String(lease.number_of_payments_1 || ''),
    },
    {
      tabLabel: 'rental_amount_1',
      value: lease.rental_amount_1
        ? `$${formatNumber(lease.rental_amount_1)}`
        : '',
    },
    {
      tabLabel: 'other_1',
      value: 'N/A',
    },
    {
      tabLabel: 'pst_1',
      value: `${
        String(lease.pst_1).toLowerCase() === 'n/a'
          ? 'N/A'
          : lease.pst_1
            ? `$${formatNumber(lease.pst_1)}`
            : ''
      }`,
    },
    {
      tabLabel: 'gst_1',
      value: lease.gst_1 ? `$${formatNumber(lease.gst_1)}` : '',
    },
    {
      tabLabel: 'total_1',
      value: lease.total_1 ? `$${formatNumber(lease.total_1)}` : '',
    },

    // Payment Terms 2
    {
      tabLabel: 'monthly',
      value: 'X',
    },
    {
      tabLabel: 'number_of_payments_2',
      value: String(lease.number_of_payments_2 || ''),
    },
    {
      tabLabel: 'rental_amount_2',
      value: lease.rental_amount_2
        ? `$${formatNumber(lease.rental_amount_2)}`
        : '',
    },
    {
      tabLabel: 'other_2',
      value: 'N/A',
    },
    {
      tabLabel: 'pst_2',
      value: `${
        String(lease.pst_2).toLowerCase() === 'n/a'
          ? 'N/A'
          : lease.pst_2
            ? `$${formatNumber(lease.pst_2)}`
            : ''
      }`,
    },
    {
      tabLabel: 'gst_2',
      value: lease.gst_2 ? `$${formatNumber(lease.gst_2)}` : '',
    },
    {
      tabLabel: 'total_2',
      value: lease.total_2 ? `$${formatNumber(lease.total_2)}` : '',
    },

    // Commencement and purchase option docs
    {
      tabLabel: 'co_lessees_combined',
      value: coLesseesCombined,
    },
    {
      tabLabel: 'asset_serial_combined_1',
      value: `${assets[0] || ''} ${serialNumbers[0] || ''}`,
    },
    {
      tabLabel: 'asset_serial_combined_2',
      value: `${assets[1] || ''} ${serialNumbers[1] || ''}`,
    },
    {
      tabLabel: 'asset_serial_combined_3',
      value: `${assets[2] || ''} ${serialNumbers[2] || ''}`,
    },
    {
      tabLabel: 'vendor',
      value: truncate(lease.vendor, SUPPLIER_MAX_LEN),
    },
    {
      tabLabel: 'purchase_option_month',
      value: String(lease.purchase_option_month || ''),
    },
    {
      tabLabel: 'purchase_option_price',
      value: `$${formatNumber(lease.purchase_price)} + taxes`,
    },
    {
      tabLabel: 'commencement_date',
      value: lease.commencement_date || '',
    },
  ]
}

const addLeaseDataToDocusignTabs = (leaseFieldValues, docusignTabs) => {
  const newPopulatedTabs = docusignTabs.map(tab => {
    const correspondingLeaseField = leaseFieldValues.find(
      leaseField => leaseField.tabLabel === tab.tabLabel,
    )

    return {
      tabId: tab.tabId,
      value: correspondingLeaseField?.value,
    }
  })

  return newPopulatedTabs
}

const populateDraftEnvelope = async (lease, envelopeId) => {
  // Generate json tab data from lease data for envelope
  const populatedLeaseFields = generateTabData(lease)

  // Pull all the tab data from draft envelope
  const getTabsResponse = await Lambda.docusign({
    action: DOCUSIGN_ACTIONS.GET_TABS,
    data: {
      envelopeId,
      documentId: 1,
    },
  })

  if (getTabsResponse.error) {
    throw new Error(getTabsResponse.message)
  }

  const docusignTabs = getTabsResponse.data.prefillTabs.textTabs

  // Insert lease field values into docusign tabs
  const newTabData = addLeaseDataToDocusignTabs(
    populatedLeaseFields,
    docusignTabs,
  )

  // update tab values by id
  const updateTabsResponse = await Lambda.docusign({
    action: DOCUSIGN_ACTIONS.UPDATE_TABS,
    data: {
      envelopeId,
      documentId: 1,
      tabs: {
        prefillTabs: {
          textTabs: newTabData,
        },
      },
    },
  })

  if (updateTabsResponse.error) {
    throw new Error(updateTabsResponse.message)
  }
}

const createDraftEnvelope = async lease => {
  const parsedCoLessees = lease.co_lessees
  const numCoLessees = Array(Object.keys(parsedCoLessees).length / 2).fill(
    'co-lessee',
  )

  const contractData = {
    templateId: templateMap[numCoLessees.length],
    templateRoles: [
      // main lessee
      {
        email: lease.main_lessee_email,
        name: lease.main_lessee_name,
        roleName: 'main_lessee',
        emailNotification: {
          emailSubject: `ADD Capital Contract Package - ${lease.main_lessee_name}`,
        },
      },
      // co-lessees
      ...numCoLessees.map((_, i) => {
        const coLesseeNumber = i + 1
        const coLesseeName = parsedCoLessees[`co_lessee_name_${coLesseeNumber}`]
        return {
          email: parsedCoLessees[`co_lessee_email_${coLesseeNumber}`],
          name: coLesseeName,
          roleName: `co_lessee_${coLesseeNumber}`,
          emailNotification: {
            emailSubject: `ADD Capital Contract Package - ${coLesseeName}`,
          },
        }
      }),
    ],
    status: 'created',
  }

  // Create draft envelope
  const createEnvelopeResponse = await Lambda.docusign({
    action: DOCUSIGN_ACTIONS.CREATE_ENVELOPE,
    data: contractData,
  })

  if (createEnvelopeResponse.error) {
    throw new Error(createEnvelopeResponse.message)
  }

  return createEnvelopeResponse.data
}

const sendDraftEnvelope = async envelopeId => {
  // sennnnnd it
  const sendEnvelopeResponse = await Lambda.docusign({
    action: DOCUSIGN_ACTIONS.UPDATE_ENVELOPE,
    data: {
      envelopeId,
      status: 'sent',
    },
  })

  if (sendEnvelopeResponse.error) {
    throw new Error(sendEnvelopeResponse.message)
  }

  return sendEnvelopeResponse.data
}

export const buildAndSendEnvelope = async lease => {
  // should hoist envelopeId so if any of the chained requests fail we can still save the envelopeId

  const draftEnvelopeData = await createDraftEnvelope(lease)

  await populateDraftEnvelope(lease, draftEnvelopeData.envelopeId)

  const sentEnvelopeData = await sendDraftEnvelope(draftEnvelopeData.envelopeId)

  return sentEnvelopeData
}

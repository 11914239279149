import React from 'react'
import PropTypes from 'prop-types'

const SearchBar = ({ placeholder, handleChange, fetchSearchedLeases }) => {
  return (
    <div className='search-bar'>
      <input
        className='search'
        type='search'
        placeholder={placeholder}
        onChange={handleChange}
        onKeyPress={async e => {
          if (e.key === 'Enter') {
            fetchSearchedLeases()
          }
        }}
      />
    </div>
  )
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  fetchSearchedLeases: PropTypes.func,
}

export default SearchBar

import axios from 'axios'

import { splitMultiLineText, formatNumber } from './helpers'

const signEasyConfig = (additionalConfig = {}) => ({
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_SIGN_EASY_API_KEY}`,
  },
  ...additionalConfig,
})

const SIGN_EASY_BASE_API_URL = 'https://api.signeasy.com/v2.1'

class SignEasy {
  getPendingDocumentStatus(id) {
    return axios(
      `${SIGN_EASY_BASE_API_URL}/template/rs/${id}/`,
      signEasyConfig(),
    )
  }

  getSignedDocumentStatus(id) {
    return axios(
      `${SIGN_EASY_BASE_API_URL}/rs/envelope/signed/pending/${id}/`,
      signEasyConfig(),
    )
  }

  downloadDocument(id) {
    return axios(
      `${SIGN_EASY_BASE_API_URL}/rs/signed/${id}/download/`,
      signEasyConfig({ responseType: 'blob' }),
    )
  }

  sendContract({ template_file_id, ...data }) {
    return axios.post(
      `${SIGN_EASY_BASE_API_URL}/template/${template_file_id}/rs`,
      data,
      signEasyConfig(),
    )
  }

  remindSigners(id) {
    return axios.post(
      `${SIGN_EASY_BASE_API_URL}/template/rs/${id}/remind/`,
      {},
      signEasyConfig(),
    )
  }

  downloadAuditTrail(id) {
    return axios(
      `${SIGN_EASY_BASE_API_URL}/template/rs/signed/${id}/certificate`,
      signEasyConfig({ responseType: 'blob' }),
    )
  }

  /**
   *
   * @param {} lease - main lease object
   * @param {} coLessees - map of co-lessee names and emails Ex. co_lessee_name_#
   * @param {Number} numCoLessees - total number of co-lessees
   * @todo Any additions made to this should be reflected in the lease schema, and contract PDF form fields
   * We want to make sure the names of fields are consistent everywhere to prevent error
   */
  generateMergeFields(lease, coLessees = {}, numCoLessees = 0) {
    const coLesseeNames = numCoLessees.map((_, i) => {
      const coLesseeNumber = i + 1
      return {
        label: `co_lessee_name_${coLesseeNumber}`,
        value: coLessees[`co_lessee_name_${coLesseeNumber}`],
        font_size: 8,
      }
    })

    let coLesseesCombined = lease.main_lessee_name
    Object.keys(lease.co_lessees).forEach(colessee => {
      if (colessee.includes('name')) {
        coLesseesCombined += ' & ' + lease.co_lessees[colessee]
      }
    })

    const assets = splitMultiLineText(lease.asset_description)
    const serialNumbers = splitMultiLineText(lease.serial_number, true)

    return [
      // Main Info
      {
        label: 'main_lessee_name',
        value: lease.main_lessee_name,
        font_size: 8,
      },
      ...coLesseeNames,
      {
        label: 'main_lessee_address',
        value: lease.main_lessee_address,
        font_size: 8,
      },
      {
        label: 'main_lessee_city',
        value: lease.main_lessee_city,
        font_size: 8,
      },
      {
        label: 'main_lessee_province',
        value: lease.main_lessee_province,
        font_size: 8,
      },
      {
        label: 'main_lessee_postal_code',
        value: lease.main_lessee_postal_code,
        font_size: 8,
      },
      {
        label: 'main_lessee_phone',
        value: lease.main_lessee_phone || '',
        font_size: 8,
      },
      {
        label: 'main_lessee_cell',
        value: lease.main_lessee_cell || '',
        font_size: 8,
      },
      {
        label: 'main_lessee_email',
        value: lease.main_lessee_email,
        font_size: 8,
      },
      {
        label: 'main_lessee_contact',
        value: lease.main_lessee_contact || '',
        font_size: 8,
      },

      // Asset Description
      {
        label: 'asset_description_1',
        value: assets[0] || '',
        font_size: 8,
      },
      {
        label: 'asset_description_2',
        value: assets[1] || '',
        font_size: 8,
      },
      {
        label: 'asset_description_3',
        value: assets[2] || '',
        font_size: 8,
      },
      {
        label: 'serial_number_1',
        value: serialNumbers[0] || '',
        font_size: 8,
      },
      {
        label: 'serial_number_2',
        value: serialNumbers[1] || '',
        font_size: 8,
      },
      {
        label: 'serial_number_3',
        value: serialNumbers[2] || '',
        font_size: 8,
      },
      {
        label: 'equipment_location',
        value: lease.equipment_location || '',
        font_size: 8,
      },

      // Other Info
      {
        label: 'sales_rep',
        value: lease.sales_rep || '',
        font_size: 8,
      },
      {
        label: 'vendor_phone_number',
        value: lease.vendor_phone_number || '',
        font_size: 8,
      },

      // Payment Terms 1
      {
        label: 'term_okd',
        value: String(lease.term_okd || ''),
        font_size: 8,
      },
      {
        label: 'number_of_payments_1',
        value: String(lease.number_of_payments_1 || ''),
        font_size: 8,
      },
      {
        label: 'rental_amount_1',
        value: lease.rental_amount_1
          ? `$${formatNumber(lease.rental_amount_1)}`
          : '',
        font_size: 8,
      },
      {
        label: 'other_1',
        value: 'N/A',
        font_size: 8,
      },
      {
        label: 'pst_1',
        value: `${
          String(lease.pst_1).toLowerCase() === 'n/a'
            ? 'N/A'
            : lease.pst_1
            ? `$${formatNumber(lease.pst_1)}`
            : ''
        }`,
        font_size: 8,
      },
      {
        label: 'gst_1',
        value: lease.gst_1 ? `$${formatNumber(lease.gst_1)}` : '',
        font_size: 8,
      },
      {
        label: 'total_1',
        value: lease.total_1 ? `$${formatNumber(lease.total_1)}` : '',
        font_size: 8,
      },

      // Payment Terms 2
      {
        label: 'monthly',
        value: 'X',
        font_size: 8,
      },
      {
        label: 'number_of_payments_2',
        value: String(lease.number_of_payments_2 || ''),
        font_size: 8,
      },
      {
        label: 'rental_amount_2',
        value: lease.rental_amount_2
          ? `$${formatNumber(lease.rental_amount_2)}`
          : '',
        font_size: 8,
      },
      {
        label: 'other_2',
        value: 'N/A',
        font_size: 8,
      },
      {
        label: 'pst_2',
        value: `${
          String(lease.pst_2).toLowerCase() === 'n/a'
            ? 'N/A'
            : lease.pst_2
            ? `$${formatNumber(lease.pst_2)}`
            : ''
        }`,
        font_size: 8,
      },
      {
        label: 'gst_2',
        value: lease.gst_2 ? `$${formatNumber(lease.gst_2)}` : '',
        font_size: 8,
      },
      {
        label: 'total_2',
        value: lease.total_2 ? `$${formatNumber(lease.total_2)}` : '',
        font_size: 8,
      },

      // Commencement and purchase option docs
      {
        label: 'co_lessees_combined',
        value: coLesseesCombined,
        font_size: 8,
      },
      {
        label: 'asset_serial_combined_1',
        value: `${assets[0] || ''} ${serialNumbers[0] || ''}`,
        font_size: 8,
      },
      {
        label: 'asset_serial_combined_2',
        value: `${assets[1] || ''} ${serialNumbers[1] || ''}`,
        font_size: 8,
      },
      {
        label: 'asset_serial_combined_3',
        value: `${assets[2] || ''} ${serialNumbers[2] || ''}`,
        font_size: 8,
      },
      {
        label: 'vendor',
        value: String(lease.vendor || ''),
        font_size: 8,
      },
      {
        label: 'purchase_option_month',
        value: String(lease.purchase_option_month || ''),
        font_size: 8,
      },
      {
        label: 'purchase_option_price',
        value: `$${formatNumber(lease.purchase_price)} + taxes`,
        font_size: 8,
      },
      {
        label: 'commencement_date',
        value: lease.commencement_date || '',
        font_size: 8,
      },
    ]
  }
}

export default new SignEasy()

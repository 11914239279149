import React from 'react'
import PropTypes from 'prop-types'
// import { ContextProvider } from '../store' // TODO: maybe implement state if we need it

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SubFooter from '../components/SubFooter'

import '../scss/style.scss'

const Layout = ({ location, bodyClass, children }) => (
  // <ContextProvider>
  <>
    <SEO />
    <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
      <div id='wrapper' className='wrapper'>
        <Header location={location} />
        {children}
      </div>
      <Footer />
    </div>
  </>
  // </ContextProvider>
)

Layout.propTypes = {
  location: PropTypes.object,
  bodyClass: PropTypes.string,
  children: PropTypes.node,
}

export default Layout

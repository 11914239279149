import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

import { Lambda, Util } from '../amplify'

import Text from './Text'
import Input from './Input'
import Button from './Button'

const EmailForm = ({ predefinedData }) => {
  const customerService = 'mike.f@addcapital.ca'
  const brokerService = 'jim.h@addcapital.ca'

  const [targetEmail, setTartgetEmail] = useState(customerService)

  const generateInitialText = () => {
    const { inquiryItem } = predefinedData

    if (inquiryItem) {
      return `I am interested in the listed item: ${inquiryItem.title}.\nYear: ${inquiryItem.year}\nLocation: ${inquiryItem.location}\n\n---- Enter any other details below ----\n\n`
    }

    return ''
  }

  const [emailInputs, setEmailInputs] = useState({
    senderName: '',
    senderEmail: '',
    subject: '',
    text: generateInitialText(),
  })
  const [emailStatus, setEmailStatus] = useState({
    message: '',
    error: false,
    loading: false,
  })

  const onEmailInputChange = e =>
    setEmailInputs({
      ...emailInputs,
      [e.target.name]: e.target.value,
    })

  const submitEmail = async e => {
    e.preventDefault()
    try {
      setEmailStatus({
        message: '',
        error: false,
        loading: true,
      })

      const emailResponse = await Lambda.sendEmail({
        IS_NOTIFICATION: false,
        TO: targetEmail,
        FROM: 'CREDIT',
        subject: `Contact Form Submission From: ${emailInputs.senderName} ${emailInputs.senderEmail}`,
        body: emailInputs.text,
      })

      setEmailStatus({
        loading: false,
        error: emailResponse.error,
        message: emailResponse.message,
      })

      if (!emailResponse.error) {
        setEmailInputs({
          senderName: '',
          senderEmail: '',
          subject: '',
          text: '',
        })
      } else {
        Util.LogError('ERROR_CONTACT_FORM_FROM_LAMBDA', emailResponse.error, {
          IS_NOTIFICATION: false,
          TO: targetEmail,
          FROM: 'CREDIT',
          subject: `Contact Form Submission From: ${emailInputs.senderName} ${emailInputs.senderEmail}`,
          body: emailInputs.text,
        })
        setEmailStatus({
          ...emailStatus,
          loading: false,
        })
      }
    } catch (error) {
      Util.LogError('ERROR_CONTACT_FORM', error, {
        IS_NOTIFICATION: false,
        TO: targetEmail,
        FROM: 'CREDIT',
        subject: `Contact Form Submission From: ${emailInputs.senderName} ${emailInputs.senderEmail}`,
        body: emailInputs.text,
      })
      setEmailStatus({
        ...emailStatus,
        loading: false,
      })
    }
  }

  return (
    <div
      className='box my-5'
      style={{
        background: '#F2F2F2',
        width: '100%',
        borderRadius: '12px',
        border: '2px solid #3b9453',
      }}
    >
      <div className='box-top' style={{ width: '100%' }}>
        <div>
          <h5>Select a department and leave us a message below.</h5>
          <hr />
        </div>
        <div className='button-group'>
          <Button
            className={`button-group-option ${
              targetEmail === customerService && 'button-option-active'
            }`}
            onClick={() => setTartgetEmail(customerService)}
          >
            Customer Service
          </Button>
          <Button
            className={`button-group-option ${
              targetEmail === brokerService && 'button-option-active'
            }`}
            onClick={() => setTartgetEmail(brokerService)}
          >
            Broker Service
          </Button>
        </div>
        <form onSubmit={submitEmail}>
          <Text error={emailStatus.error} style={{ fontStyle: 'italic' }}>
            {emailStatus.message}
          </Text>
          <Input
            name='senderName'
            type='text'
            label='Enter Your Name'
            value={emailInputs.senderName}
            onChange={onEmailInputChange}
            required
            disabled={emailStatus.loading}
          />
          <Input
            name='senderEmail'
            type='email'
            label='Enter Your Email'
            value={emailInputs.senderEmail}
            onChange={onEmailInputChange}
            required
            disabled={emailStatus.loading}
          />
          <Input
            name='text'
            textarea
            rows='6'
            label='Enter Your Message'
            value={emailInputs.text}
            onChange={onEmailInputChange}
            required
            disabled={emailStatus.loading}
          />
          {emailStatus.loading ? (
            <ClipLoader loading size='35px' color='#3b9453' />
          ) : (
            <div className='col-12' style={{ textAlign: 'center' }}>
              <Button buttonClass='button-submission' type='submit'>
                Send Email
              </Button>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

EmailForm.propTypes = {
  predefinedData: PropTypes.object,
}

export default EmailForm

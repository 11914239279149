import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

import { Storage, Util } from '../amplify'
import { createToast } from '../util/reactHelpers'

import Input from './Input'
import Button from './Button'

// File Upload Container with state + input addition logic
const FileUpload = ({
  leaseMetaData,
  disabled = false,
  noStartingInput = false,
  type = 'credit',
  disableAdding = false,
  constantFileName = null,
  // hook to be run after a file upload or delete
  fileActionHook = () => {},
}) => {
  const [files, setFiles] = useState([])
  // const [uploadFilesSize, setUploadFilesSize] = useState(0)
  // const [totalFilesSize, setTotalFilesSize] = useState(0)
  const [fileLoading, setFileLoading] = useState(false)

  const cleanupFiles = () => {
    setFiles([])
    // setUploadFilesSize(0)
    // setTotalFilesSize(0)
    document.getElementsByName('multi_file_input')[0].value = null
  }

  const removeLocalFiles = () => {
    document.getElementsByName('multi_file_input')[0].value = null
  }

  // remove all files in multi file
  const removeFiles = async () => {
    setFileLoading(true)

    const removeFilesPromises = files.map(file => {
      return Storage.deleteDocument(
        `leases/${leaseMetaData.id}/${type}/${constantFileName || file}`,
      )
    })

    try {
      await Promise.all(removeFilesPromises)
      createToast('Successfully removed files.')
    } catch (error) {
      createToast('Files removal failed. Try again.', 'error')
      Util.LogError('ERROR_REMOVING_FILES', error, files)
    }

    cleanupFiles()
    fileActionHook()
    setFileLoading(false)
  }

  // add all selected files to storage bucket
  const onFileChange = async e => {
    e.preventDefault()
    e.stopPropagation()
    e.persist()
    setFileLoading(true)

    const iterableFiles = Array.from(e.target.files)

    // set total files upload size
    // setTotalFilesSize(iterableFiles.reduce((acc, file) => acc + file.size, 0))

    const filePromises = iterableFiles.map(async file => {
      return Storage.uploadDocument(
        `leases/${leaseMetaData.id}/${type}/${constantFileName || file.name}`,
        file,
        // progress => {
        //   setUploadFilesSize(uploadFilesSize + progress.loaded)
        // },
      )
    })

    try {
      await Promise.all(filePromises)

      // set file names in state after upload
      setFiles([...files, ...iterableFiles.map(file => file.name)])

      createToast('Successfully added files.')
    } catch (error) {
      createToast('Failed to add files. Try again.', 'error')
      Util.LogError('ERROR_ADDING_FILES', error, iterableFiles)
      cleanupFiles()
    }

    removeLocalFiles()
    fileActionHook()
    setFileLoading(false)
  }

  return (
    <div>
      <div>
        <p>Current Files: </p>
        <ul>
          {files.map(file => (
            <li key={file}>{file}</li>
          ))}
        </ul>
      </div>
      {/* initial file input */}
      {!disableAdding && (
        <Input
          id='multi_file_input'
          name='multi_file_input'
          type='file'
          accept='*'
          multiple
          onChange={onFileChange}
          // progress={progress}
          inputStyles={{ border: 'none', overflowX: 'auto' }}
          fullBorderStyles={{
            border: '1px solid #ced4da',
            borderRadius: '4px',
            padding: '4px 0',
          }}
          disabled={disabled}
          append={
            fileLoading ? (
              <div className='mr-2'>
                <ClipLoader loading size='25px' color='#3b9453' />
              </div>
            ) : (
              <Button
                style={{ margin: '0 10px' }}
                buttonClass='button-danger'
                onClick={removeFiles}
                disabled={disabled}
              >
                Remove All Files
              </Button>
            )
          }
        />
      )}
    </div>
  )
}

FileUpload.propTypes = {
  leaseMetaData: PropTypes.object,
  disabled: PropTypes.bool,
  noStartingInput: PropTypes.bool,
  type: PropTypes.string,
  disableAdding: PropTypes.bool,
  constantFileName: PropTypes.string,
  fileActionHook: PropTypes.func,
}

export default FileUpload

import React, { useState } from 'react'
import Layout from '../../layouts/index'
import SEO from '../../components/SEO'

import Text from '../../components/Text'
import Input from '../../components/Input'
import Button from '../../components/Button'
import RegisterForm from '../../components/Register'

const Register = () => {
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const onCheckPassword = e => {
    e.preventDefault()

    if (String(password) === process.env.GATSBY_REGISTER_PASSWORD) {
      setIsPasswordCorrect(true)
      setPasswordError('')
    } else {
      setPasswordError('Incorrect Register Password')
    }
  }

  return (
    <Layout bodyClass='page-contact gradient'>
      <SEO title='Register' />

      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='auth'>
              <div className='auth-box-top'>
                <div className='col-12 intro intro-small'>
                  <h1>Broker Register</h1>
                </div>
                {isPasswordCorrect ? (
                  <RegisterForm />
                ) : (
                  <form
                    onSubmit={onCheckPassword}
                    style={{ padding: '0 10px' }}
                  >
                    <Text
                      error={!!passwordError}
                      style={{ fontStyle: 'italic', padding: '5px 0 10px 0' }}
                    >
                      {passwordError}
                    </Text>
                    <Input
                      inputStyles={{ width: 240 }}
                      placeholder='Enter Registration Password'
                      type='password'
                      onChange={e => setPassword(e.target.value)}
                    />
                    <Button type='submit'>Submit</Button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Register

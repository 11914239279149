import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'
import { navigate } from 'gatsby'

import { Auth } from '../amplify'

import Text from './Text'
import Input from './Input'
import Button from './Button'

const ResetPassword = ({ refreshAuthStatus }) => {
  const [resetPasswordCredentials, setResetPasswordCredentials] = useState({
    username: '', // actually an email, just named username
    newPassword: '',
    verificationCode: null,
  })
  const [resetPasswordStatus, setResetPasswordStatus] = useState({
    message: '',
    error: false,
    loading: false,
    successful: false,
    verificationCodeSent: false,
  })

  const onResetPasswordInputChange = e =>
    setResetPasswordCredentials({
      ...resetPasswordCredentials,
      [e.target.name]: e.target.value,
    })

  const sendForgotPasswordCode = async () => {
    setResetPasswordStatus({ ...resetPasswordStatus, loading: true })
    const forgotPasswordResponse = await Auth.forgotPassword(
      resetPasswordCredentials.username.toLowerCase(),
    )

    if (forgotPasswordResponse?.CodeDeliveryDetails) {
      setResetPasswordStatus({
        message: `A verfication code has been sent to ${
          forgotPasswordResponse?.CodeDeliveryDetails?.Destination ||
          'your registered phone number'
        }`,
        error: false,
        loading: false,
        successful: false,
        verificationCodeSent: true,
      })
    } else {
      setResetPasswordStatus({
        message:
          forgotPasswordResponse?.message ||
          'An unknown error occured. Please try again later.',
        error: true,
        loading: false,
        successful: false,
        verificationCodeSent: false,
      })
    }
  }

  const submitNewPassword = async () => {
    setResetPasswordStatus({ ...resetPasswordStatus, loading: true })
    const forgotPasswordSubmitResponse = await Auth.forgotPasswordSubmit(
      resetPasswordCredentials.username.toLowerCase(),
      resetPasswordCredentials.verificationCode,
      resetPasswordCredentials.newPassword,
    )

    // This request returns nothing when successful
    if (!forgotPasswordSubmitResponse) {
      setResetPasswordStatus({
        message: 'Successfully Updated Password',
        error: false,
        loading: false,
        successful: true,
        verificationCodeSent: true,
      })
      setResetPasswordCredentials({
        username: '',
        newPassword: '',
        verificationCode: null,
      })
      setTimeout(() => {
        refreshAuthStatus()
        navigate('/portal')
      }, 2500)
    } else {
      setResetPasswordStatus({
        message:
          forgotPasswordSubmitResponse?.message ||
          'An unknown error occured. Please try again later.',
        error: false,
        loading: false,
        successful: false,
        verificationCodeSent: true,
      })
    }
  }

  return (
    <div className='auth col-12'>
      <div className='auth-box-top'>
        <div className='col-12 intro intro-small'>
          <h1>Reset Password</h1>
        </div>

        {resetPasswordStatus.verificationCodeSent ? (
          <>
            <Text
              error={resetPasswordStatus.error}
              style={{ fontStyle: 'italic', padding: '5px 0 10px 0' }}
            >
              {resetPasswordStatus.message}
            </Text>
            <Input
              name='username'
              type='email'
              placeholder='Enter Email'
              prepend='&#128231;'
              onChange={onResetPasswordInputChange}
              required
              disabled={resetPasswordStatus.loading}
              value={resetPasswordCredentials.username}
            />
            <Input
              name='newPassword'
              type='password'
              placeholder='Enter New Password'
              prepend='&#128274;'
              onChange={onResetPasswordInputChange}
              required
              disabled={resetPasswordStatus.loading}
              value={resetPasswordCredentials.password}
            />
            <Input
              name='verificationCode'
              placeholder='Enter Verification Code'
              prepend='&#128274;'
              onChange={onResetPasswordInputChange}
              required
              disabled={resetPasswordStatus.loading}
              value={resetPasswordCredentials.password}
            />
            <>
              {resetPasswordStatus.loading ? (
                <ClipLoader loading size='35px' color='#3b9453' />
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Button type='button' onClick={submitNewPassword}>
                    Update Password
                  </Button>
                </div>
              )}
            </>
          </>
        ) : (
          <>
            <Text
              error={resetPasswordStatus.error}
              style={{ fontStyle: 'italic', padding: '5px 0 10px 0' }}
            >
              {resetPasswordStatus.message}
            </Text>
            <Input
              name='username'
              type='email'
              placeholder='Enter Your Email'
              prepend='&#128231;'
              onChange={onResetPasswordInputChange}
              required
              disabled={resetPasswordStatus.loading}
              value={resetPasswordCredentials.username}
            />
            {resetPasswordStatus.loading ? (
              <ClipLoader loading size='35px' color='#3b9453' />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Button type='button' onClick={sendForgotPasswordCode}>
                  Send Verification Code
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

ResetPassword.propTypes = {
  refreshAuthStatus: PropTypes.func,
}

export default ResetPassword

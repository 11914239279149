import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import moment from 'moment'

import { API, Util } from '../amplify'
import Button from './Button'
import { createToast, createModal } from '../util/reactHelpers'
import { isMobile } from '../util/helpers'
import * as LEASE_STATUS from '../data/leaseStatus'

const LeaseBar = ({ lease, broker, fetchLeaseData, IS_ADMIN }) => {
  const IS_MOBILE = isMobile(1000)

  const [modalData, setModalData] = useState({
    isOpen: false,
    title: '',
    description: '',
    submitText: '',
    submitHandler: () => {},
  })

  const renderAssetDescription = () => {
    const n = IS_MOBILE ? 21 : 70
    return lease?.asset_description.length > n
      ? lease?.asset_description.substr(0, n - 1) + '....'
      : lease?.asset_description
  }

  const archiveLease = async () => {
    const status = LEASE_STATUS.LEASE_ARCHIVED.status

    try {
      await API.updateLease({
        ...lease,
        co_lessees: JSON.stringify(lease?.co_lessees || {}),
        status,
      })

      // Reset modal state
      setModalData({
        isOpen: false,
        title: '',
        description: '',
        submitText: '',
        submitHandler: () => {},
      })

      // Re fetch leases to update UI
      await fetchLeaseData()

      createToast('Successfully archived lease')
    } catch (error) {
      Util.LogError('ERROR_ARCHIVING_LEASE', error, {
        ...lease,
        co_lessees: JSON.stringify(lease?.co_lessees || {}),
        status,
      })
      createToast(
        'Failed to archive lease application. Please try again.',
        'error',
      )
    }
  }
  return (
    <div className='lease-bar'>
      <div
        className='lease-bar-content ml-1'
        onClick={() => navigate(`/portal/lease/${lease?.id}`)}
      >
        <div className='lease-bar-left-content'>
          <div className='lease-bar-title'>
            Lessee:&nbsp;
            <b>{lease?.main_lessee_name}</b>
          </div>
          <div className='lease-bar-title'>
            Equipment:&nbsp;
            <b>{renderAssetDescription()}</b>
          </div>
        </div>
        <div className='lease-bar-info'>
          <div className='lease-bar-row'>
            <div className='lease-bar-key'>Broker:&nbsp;</div>
            <div className='lease-bar-value'>{broker?.name}</div>
          </div>
          <div className='lease-bar-row'>
            <div className='lease-bar-key'>Created:&nbsp;</div>
            <div className='lease-bar-value'>
              {moment(lease.createdAt).format('MMMM DD, YYYY h:mm A')}
            </div>
          </div>
        </div>
      </div>
      {IS_ADMIN && lease.status !== LEASE_STATUS.LEASE_ARCHIVED.status && (
        <Button
          style={{ margin: 'auto 10px' }}
          buttonClass='button-danger'
          onClick={() =>
            setModalData({
              isOpen: true,
              title: 'Confirm Archive',
              description:
                'Are you sure you want to archive this lease application? You will still be able to access it from the "Archived" section.',
              submitText: 'Archive',
              submitHandler: archiveLease,
            })
          }
        >
          X
        </Button>
      )}
      {createModal(modalData, setModalData)}
    </div>
  )
}

LeaseBar.propTypes = {
  lease: PropTypes.object,
  broker: PropTypes.object,
  fetchLeaseData: PropTypes.func,
  IS_ADMIN: PropTypes.bool,
}

export default LeaseBar

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import Layout from '../../layouts/index'
import SEO from '../../components/SEO'
import StripeForm from '../../components/StripeForm'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

const Payment = props => {
  const [paymentInfo, setPaymentInfo] = useState({
    name: '',
    email: '',
    businessName: '',
    amount: 0.0,
    adminFee: 0.0,
    totalAmount: 0.0,
  })

  const setField = e => {
    if (e === 'SUCCESS') {
      setPaymentInfo({
        name: '',
        email: '',
        businessName: '',
        amount: 0.0,
        adminFee: 0.0,
        totalAmount: 0.0,
      })
    } else if (e.target.name === 'amount') {
      const payAmt = Number(e.target.value)
      const adminFee = payAmt * 0.0325
      setPaymentInfo({
        ...paymentInfo,
        amount: payAmt,
        adminFee: Number(adminFee.toFixed(2)),
        totalAmount: Number(Number(payAmt + adminFee).toFixed(2)),
      })
    } else {
      setPaymentInfo({
        ...paymentInfo,
        [e.target.name]: e.target.value,
      })
    }
  }

  return (
    <Layout bodyClass='gradient'>
      <SEO title='Payment Centre' />
      <div className='page-banner payment-background'>
        <div className='page-title'>Payment Centre</div>
      </div>

      <div className='container'>
        <div className='row payment-container d-flex'>
          <div className='payment-left'>
            <div className='payment-bg' />
            <div className='payment-amount-container'>
              <div className='payment-amount-text'>Total Due</div>
              <div className='payment-amount-total'>
                {`$${paymentInfo.totalAmount?.toLocaleString('en-US')}`}
              </div>
            </div>
          </div>
          <div className='payment-right'>
            <Elements stripe={stripePromise}>
              <StripeForm setField={setField} paymentInfo={paymentInfo} />
            </Elements>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PaymentQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/payment/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`

Payment.propTypes = {
  data: PropTypes.object,
}

export default Payment

import React from 'react'
import { toast } from 'react-toastify'
import Modal from 'react-modal'
import moment from 'moment'

import leaseCreditFormSchema from '../data/leaseCreditFormSchema.json'
import leaseCreditLogSchema from '../data/leaseCreditLogSchema.json'
import leaseContractFormSchema from '../data/leaseContractFormSchema.json'

import Input from '../components/Input'
import CoLessees from '../components/CoLessees'
import Button from '../components/Button'

/* @param schema: 'credit' | 'contract'
   @param labelType: 'edit' | 'view'
   @param inputProps: {
            disabled: Bool,
            onChange: func,
            leaseData: object
          }

  @return <JSX Input />[]
*/
export const renderForm = ({
  schema,
  labelType,
  IS_ADMIN,
  IS_MOBILE,
  inputProps,
}) => {
  let inputSchema = []

  switch (schema) {
    case 'creditGeneral':
      inputSchema = leaseCreditFormSchema
      break
    case 'creditLog':
      inputSchema = leaseCreditLogSchema
      break
    case 'contract':
      inputSchema = leaseContractFormSchema
      break
    default:
      console.log('Unknown schema. Please Try again.')
  }

  if (IS_MOBILE) {
    return inputSchema.flat().map(row => {
      // if single input per row
      const { inputStyles, labels, ...field } = row

      // for co-lessee field
      if (field.name === 'co_lessees') {
        return (
          <CoLessees
            key='co_lessees'
            mainLeaseInputHandler={inputProps.onChange}
            data={inputProps.data}
            disabled={inputProps.disabled}
            options={field.options}
            IS_ADMIN={IS_ADMIN}
          />
        )
      }
      return (
        <Input
          key={field.name}
          inputStyles={inputStyles}
          label={labelType === 'edit' ? labels.edit : labels.view}
          disabled={inputProps.disabled}
          onChange={inputProps.onChange}
          value={
            inputSchema === leaseCreditLogSchema
              ? inputProps.data?.[field.name]
                ? inputProps.data?.[field.name]
                : inputProps.data === 0
                ? 0
                : undefined
              : inputSchema === leaseContractFormSchema // this should really be fixed for real but its working so ¯\_(ツ)_/¯
              ? inputProps.data?.[field.name]
                ? inputProps.data?.[field.name]
                : ''
              : inputProps.data?.[field.name]
              ? inputProps.data?.[field.name]
              : undefined
          }
          {...field}
        />
      )
    })
  }

  return inputSchema.map((row, i) => {
    // if multiple inputs per row
    if (row.length > 1) {
      return (
        <div className='input-row' key={`row-${i}`}>
          {row.map(({ inputStyles, labels, ...field }) => (
            <Input
              key={field.name}
              inputStyles={inputStyles}
              label={labelType === 'edit' ? labels.edit : labels.view}
              disabled={inputProps.disabled}
              onChange={inputProps.onChange}
              fullBorderStyles={{
                flexBasis: `${100 / row.length - (row.length > 6 ? 1 : 2)}%`,
              }}
              value={
                inputSchema === leaseCreditLogSchema
                  ? inputProps.data?.[field.name]
                    ? inputProps.data?.[field.name]
                    : inputProps.data[field.name] === 0
                    ? 0
                    : undefined
                  : inputSchema === leaseContractFormSchema
                  ? inputProps.data?.[field.name]
                    ? inputProps.data?.[field.name]
                    : ''
                  : inputProps.data?.[field.name]
                  ? inputProps.data?.[field.name]
                  : undefined
              }
              {...field}
            />
          ))}
        </div>
      )
    }

    // if single input per row
    const { inputStyles, labels, ...field } = row[0]

    // for co-lessee field
    if (field.name === 'co_lessees') {
      return (
        <CoLessees
          key='co_lessees'
          mainLeaseInputHandler={inputProps.onChange}
          data={inputProps.data}
          disabled={inputProps.disabled}
          options={field.options}
          IS_ADMIN={IS_ADMIN}
        />
      )
    }
    return (
      <Input
        key={field.name}
        inputStyles={inputStyles}
        label={labelType === 'edit' ? labels.edit : labels.view}
        disabled={inputProps.disabled}
        onChange={inputProps.onChange}
        value={
          inputSchema === leaseCreditLogSchema
            ? inputProps.data?.[field.name]
              ? inputProps.data?.[field.name]
              : inputProps.data === 0
              ? 0
              : undefined
            : inputSchema === leaseContractFormSchema // this should really be fixed for real but its working so ¯\_(ツ)_/¯
            ? inputProps.data?.[field.name]
              ? inputProps.data?.[field.name]
              : ''
            : inputProps.data?.[field.name]
            ? inputProps.data?.[field.name]
            : undefined
        }
        {...field}
      />
    )
  })
}

export const createToast = (content, type = 'success') => {
  const toastProps = {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      borderRadius: 5,
      textAlign: 'center',
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
  }

  if (type === 'success') {
    return toast.success(content, {
      ...toastProps,
      style: { ...toastProps.style, background: '#3b9453' },
    })
  }

  if (type === 'error') {
    return toast.error(content, {
      ...toastProps,
    })
  }

  console.log('INVALID TOAST')
}

Modal.setAppElement('#___gatsby')
export const createModal = (modalData, setModalData) => {
  return (
    <Modal
      isOpen={modalData.isOpen}
      onRequestClose={() => setModalData({ ...modalData, isOpen: false })}
      className='modal-content'
      contentLabel={modalData?.contentLabel || 'ADD Confirm Modal'}
      style={{
        overlay: { zIndex: 10 },
        content: {
          maxHeight: '100%',
          ...(modalData?.noMarginTop ? { marginTop: 0 } : {}),
        },
      }}
    >
      <h2 style={{ color: '#ffffff' }}>{modalData.title}</h2>
      {modalData?.isNode ? (
        <div style={{ color: '#ffffff', maxHeight: 500, overflowY: 'scroll' }}>
          {modalData.description}
        </div>
      ) : (
        <p style={{ color: '#ffffff', maxHeight: 500, overflowY: 'scroll' }}>
          {modalData.description}
        </p>
      )}
      <div className='d-flex justify-content-between'>
        <Button
          buttonClass='button-danger'
          onClick={() => setModalData({ ...modalData, isOpen: false })}
        >
          Close
        </Button>
        {modalData.submitText && (
          <Button onClick={modalData?.submitHandler}>
            {modalData.submitText}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export const formatDocusignAuditData = auditData => {
  const parsedEventItems = auditData.auditEvents.map(event => {
    const eventItemsArr = event.eventFields

    const time = eventItemsArr.find(item => item.name === 'logTime').value
    const source = eventItemsArr.find(item => item.name === 'Source').value
    const name = eventItemsArr.find(item => item.name === 'UserName').value
    const id = eventItemsArr.find(item => item.name === 'UserId').value
    const action = eventItemsArr.find(item => item.name === 'Action').value
    const message = eventItemsArr.find(item => item.name === 'Message').value
    const envelopeStatus = eventItemsArr.find(
      item => item.name === 'EnvelopeStatus',
    ).value
    const ip = eventItemsArr.find(item => item.name === 'ClientIPAddress').value

    return {
      time,
      source,
      name,
      id,
      action,
      message,
      envelopeStatus,
      ip,
    }
  })

  return (
    <div>
      {parsedEventItems.map(({ message, time, ...rest }) => (
        <details style={{ marginBottom: 30 }} key={message}>
          <summary>
            <b>
              {message}
              <br />({moment(time).format('MMMM DD, YYYY h:mm A')})
            </b>
          </summary>
          {Object.keys(rest).map(key => (
            <li key={key}>
              <span style={{ textDecoration: 'underline' }}>{key}</span>:{' '}
              {rest[key]}
            </li>
          ))}
        </details>
      ))}
    </div>
  )
}

import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'
import { ClipLoader } from 'react-spinners'

import Button from './Button'

export function ESignatureStatus({
  documentInfo,
  viewContractAuditTrail,
  viewContractCertificate,
  signReminderData,
  remindLessees,
  lease,
}) {
  const isDocusign = documentInfo.isDocusign
  // Maps docusign role names to our internal lessee names
  const lesseeMap = {
    main_lessee: lease.main_lessee_name,
    co_lessee_1: lease.co_lessees?.co_lessee_name_1,
    co_lessee_2: lease.co_lessees?.co_lessee_name_2,
    co_lessee_3: lease.co_lessees?.co_lessee_name_3,
  }

  const sentDate = isDocusign
    ? new Date(documentInfo.sentDateTime).getTime() / 1000
    : documentInfo.created_time
  const docName = isDocusign
    ? documentInfo.envelopeDocuments[0].name
    : documentInfo.name
  const isCompleted = isDocusign
    ? documentInfo.status === 'completed'
    : documentInfo.status === 'complete'

  const recipientMarkup = (
    isDocusign ? documentInfo.recipients.signers : documentInfo.recipients
  ).map(recipient => {
    const key = isDocusign
      ? recipient.recipientIdGuid
      : recipient.email + recipient.last_modified_time
    const dateFinalized = isDocusign
      ? new Date(recipient.signedDateTime).getTime() / 1000
      : recipient.last_modified_time

    const docusignLesseeName = lesseeMap[recipient.roleName]

    return (
      <div className='mb-1' key={key}>
        <div>
          <span className='signature-field-title'>Lessee</span>
          {` - ${isDocusign ? docusignLesseeName : recipient.first_name}`}
        </div>
        <div>
          <span className='signature-field-title'>Email</span>
          {` - ${recipient.email}`}
        </div>
        <div>
          <span className='signature-field-title'>Status</span>
          {` - ${
            isDocusign
              ? recipient.status
              : recipient.status.split('_').join(' ')
          }`}
        </div>
        {isCompleted && (
          <div>
            <span className='signature-field-title'>Date Finalized</span>
            {` - ${moment.unix(dateFinalized).format('MMMM DD, YYYY h:mm A')}`}
          </div>
        )}
      </div>
    )
  })

  return (
    <div>
      <div>
        <span className='signature-field-title'>Status</span>
        {` - ${documentInfo.status}`}
      </div>
      <div>
        <span className='signature-field-title'>Date sent</span>
        {` -
        ${moment.unix(sentDate).format('MMMM DD, YYYY h:mm A')}`}
      </div>
      <div>
        <span className='signature-field-title'>Name of Document</span>
        {` - ${docName}`}
      </div>
      {isCompleted && (
        // TODO: should this only be available to admins?
        <>
          <div style={{ margin: '20px 0 10px 0' }}>
            <Button onClick={viewContractAuditTrail}>
              View Contract Audit Trail
            </Button>
          </div>
          {isDocusign && (
            <div style={{ margin: '20px 0 10px 0' }}>
              <Button onClick={viewContractCertificate}>
                View Signed Contract Certificate
              </Button>
            </div>
          )}
        </>

      )}
      <div className='pt-1'>
        <b style={{ textDecoration: 'underline' }}>Lessee Signature Status</b>
      </div>
      {recipientMarkup}
      {!isDocusign && documentInfo.status === 'incomplete' && (
        <div style={{ paddingTop: 20 }}>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: 10,
              color: 'red',
            }}
          >
            ATTENTION: This will send an email reminder to all lessees who have
            not yet signed.
          </p>
          {signReminderData.loading ? (
            <div
              className='centeredContentPage'
              style={{ minHeight: 30, paddingTop: 30 }}
            >
              <ClipLoader loading size='30px' color='#3b9453' />
            </div>
          ) : (
            <Button onClick={remindLessees}>Remind Lessee(s)</Button>
          )}
        </div>
      )}
    </div>
  )
}

ESignatureStatus.propTypes = {
  documentInfo: propTypes.object,
  viewContractAuditTrail: propTypes.func,
  viewContractCertificate: propTypes.func,
  signReminderData: propTypes.object,
  remindLessees: propTypes.func,
  lease: propTypes.object,
}

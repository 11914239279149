import React from 'react'
import PropTypes from 'prop-types'

import headshot1 from '../images/headshot4.svg'

const ContactCard = ({ name, title, email, phone, body }) => {
  return (
    <div
      className='box my-5'
      style={{
        background: '#F2F2F2',
        width: '100%',
        borderRadius: '12px',
        border: '2px solid #3b9453',
      }}
    >
      <div className='box-top contact-card'>
        <div className='contact-card-header'>
          <div className='header-info'>
            <div style={{ width: '50%' }}>
              <h3>
                <b>{name}</b>
              </h3>
              <h5>{title}</h5>
            </div>
            <div style={{ width: '50%' }}>
              <p>
                Email: {email}
                <br />
                Phone: {phone}
              </p>
            </div>
          </div>
        </div>
        <div className='col-12'>
          <hr />
        </div>
        <div className='contact-card-body'>{body}</div>
      </div>
    </div>
  )
}

ContactCard.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  body: PropTypes.string,
}

export default ContactCard

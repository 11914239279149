import React from 'react'
import propTypes from 'prop-types'

import Input from './Input'

const LeaseDenied = ({ lease, type }) => {
  return (
    <div className='info-block'>
      <>
        <b>Lease Denied</b>
        <div className='mb-5'>
          Your lease was denied for the reasons below. Contact{' '}
          <a href='mailto:credit@addcapital.ca'>credit@addcapital.ca</a> for
          more information if needed.
        </div>
        <Input
          label='Admin Feedback:'
          textarea
          disabled
          value={
            type === 'credit'
              ? lease.admin_credit_feedback || ''
              : lease.admin_contract_feedback || ''
          }
          rows={8}
          boldLabel
        />
      </>
    </div>
  )
}

LeaseDenied.propTypes = {
  lease: propTypes.object,
  type: propTypes.string,
}

export default LeaseDenied

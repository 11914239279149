/* eslint-disable react/function-component-definition */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'

import { Auth, API, Util } from '../amplify'
import * as LEASE_STATUS from '../data/leaseStatus'

import PortalPage from './PortalPage'
import Button from './Button'
import LeaseSquare from './LeaseSquare'
import BrokerDropdown from './BrokerDropdown'

const BrokerHome = ({
  refreshAuthStatus,
  user,
  brokerList,
  selectedBrokerFilter,
  setSelectedBrokerFilter,
}) => {
  const IS_ADMIN = user.attributes?.['custom:type'] === 'admin'

  const [data, setData] = useState({
    loading: true,
    leaseCounts: {},
  })

  useEffect(() => {
    fetchLeaseCounts()
  }, [selectedBrokerFilter])

  const fetchLeaseCounts = async () => {
    try {
      const leaseResponse = await API.getLeaseCounts(
        user.attributes['custom:type'] === 'broker'
          ? user.username
          : selectedBrokerFilter[0].value,
      )

      setData({
        ...data,
        loading: false,
        leaseCounts: leaseResponse,
      })
    } catch (error) {
      Util.LogError(
        'ERROR_FETCHING_LEASE_COUNTS',
        error,
        user.attributes['custom:type'] === 'broker'
          ? user.username
          : selectedBrokerFilter[0].value,
      )
    }
  }

  const onDropdownChange = option => {
    const value = option.length ? option : [{ label: 'No Filter', value: '' }]
    setSelectedBrokerFilter(value)
  }

  return (
    <PortalPage
      user={user}
      headerLinks={[
        <Link className='button' to='/portal/lease/new' key='newLeaseLink'>
          + New Lease Application
        </Link>,
        IS_ADMIN ? (
          <Button
            className='button'
            to='/portal/lease/new'
            key='archivedLink'
            onClick={() =>
              navigate(`/portal/leases/${LEASE_STATUS.LEASE_ARCHIVED.url}`)
            }
          >
            View Archived
          </Button>
        ) : null,
        <BrokerDropdown
          key='brokerDropdown'
          IS_ADMIN={IS_ADMIN}
          brokerList={brokerList}
          selectedBrokerFilter={selectedBrokerFilter}
          onDropdownChange={onDropdownChange}
        />,
        <Button
          buttonClass='button-danger'
          onClick={async () => {
            await Auth.logout()
            refreshAuthStatus()
          }}
          key='logoutLink'
        >
          Logout
        </Button>,
      ]}
    >
      <div className='dashboard'>
        <div className='dashboard-title'>VIEW APPLICATIONS IN CREDIT STAGE</div>
        <div className='lease-square-container'>
          <LeaseSquare
            status='Pending Credit'
            location={LEASE_STATUS.LEASE_PENDING_CREDIT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_PENDING_CREDIT.status}_QUERY`
              ]
            }
          />
          <LeaseSquare
            status='Approved Credit'
            location={LEASE_STATUS.LEASE_APPROVED_CREDIT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_APPROVED_CREDIT.status}_QUERY`
              ]
            }
          />
          <LeaseSquare
            status='Needs More Credit Information'
            location={LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status}_QUERY`
              ]
            }
          />
          <LeaseSquare
            status='Denied Credit'
            location={LEASE_STATUS.LEASE_DENIED_CREDIT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_DENIED_CREDIT.status}_QUERY`
              ]
            }
          />
        </div>

        <div className='dashboard-divider' />

        <div className='dashboard-title'>
          VIEW APPLICATIONS IN CONTRACT STAGE
        </div>
        <div className='lease-square-container'>
          <LeaseSquare
            status='Pending Contract'
            location={LEASE_STATUS.LEASE_PENDING_CONTRACT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_PENDING_CONTRACT.status}_QUERY`
              ]
            }
          />
          <LeaseSquare
            status='Approved Contract'
            location={LEASE_STATUS.LEASE_APPROVED_CONTRACT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_APPROVED_CONTRACT.status}_QUERY`
              ]
            }
          />
          <LeaseSquare
            status='Needs More Contract Information'
            location={LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.url}
            leases={
              data.leaseCounts[
                `${LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status}_QUERY`
              ]
            }
          />
          <LeaseSquare
            status='Completed'
            location={LEASE_STATUS.LEASE_COMPLETE.url}
            leases={
              data.leaseCounts[`${LEASE_STATUS.LEASE_COMPLETE.status}_QUERY`]
            }
          />
        </div>
      </div>
    </PortalPage>
  )
}

BrokerHome.propTypes = {
  user: PropTypes.object,
  refreshAuthStatus: PropTypes.func,
  brokerList: PropTypes.array,
  selectedBrokerFilter: PropTypes.array,
  setSelectedBrokerFilter: PropTypes.func,
}

export default BrokerHome

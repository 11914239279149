import React from 'react'
import propTypes from 'prop-types'
import moment from 'moment'

import { Storage, Util } from '../amplify'
import { parseLeaseFiles } from '../util'
import * as LEASE_STATUS from '../data/leaseStatus'

import Button from './Button'
import FileUpload from './FileUpload'

const LeaseFiles = ({ data, setData, isDisabled = false, type, IS_ADMIN }) => {
  const files = data.files[type]

  const IS_ALLOWED_TO_ADD_FILES =
    (data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status &&
      type === 'credit') ||
    (data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status &&
      type === 'contract') ||
    (data.lease.status === LEASE_STATUS.LEASE_APPROVED_CREDIT.status &&
      type === 'contract')

  let typeOfFile = type.charAt(0).toUpperCase() + type.slice(1)
  typeOfFile = typeOfFile.replace(/([a-z])([A-Z0-9])/g, '$1 $2')

  const removeExistingFile = async file => {
    try {
      await Storage.deleteDocument(file)
      const updatedFiles = await Storage.listDocuments(
        `leases/${data.lease.id}`,
      )
      const sortedFiles = parseLeaseFiles(updatedFiles)

      setData({
        ...data,
        files: sortedFiles,
      })
    } catch (error) {
      Util.LogError('ERROR_REMOVING_EXISTING_FILE', error, data)
    }
  }

  const renderDeleteButton = file => {
    return (
      (IS_ADMIN ||
        (data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CREDIT.status &&
          type === 'credit') ||
        (data.lease.status === LEASE_STATUS.LEASE_NEEDS_INFO_CONTRACT.status &&
          type === 'contract')) && (
        <Button
          style={{ margin: '0 10px' }}
          buttonClass='button-danger'
          disabled={isDisabled}
          onClick={() => removeExistingFile(file.key)}
        >
          X
        </Button>
      )
    )
  }

  const viewExistingFile = async file => {
    try {
      const fileURL = await Storage.getDocumentURL(file)
      window.open(fileURL, '_blank')
    } catch (error) {
      Util.LogError('ERROR_FETCHING_EXISTING_FILE', error, file)
    }
  }

  return (
    <>
      <div className='mb-5 full-file-container'>
        <b>
          {files.length
            ? `Attached ${typeOfFile} Files`
            : IS_ALLOWED_TO_ADD_FILES
            ? `Add Lease ${typeOfFile} Documents`
            : `No Attached ${typeOfFile} Files`}
        </b>

        {/* Current Files Rendered */}
        <div style={{ marginTop: 5 }}>
          {files.map(file => {
            const fileName = file.key.split('/')[3]
            const fileAdded = file.lastModified

            // Render all files normally if you are an admin.
            // If you are a broker, never render the credit decision doc, and only render the
            // approval doc once we are passed the pending credit stage (credit has been approved)
            return !IS_ADMIN &&
              (fileName === 'credit_decision_doc.pdf' ||
                (fileName === 'credit_approval_doc.pdf' &&
                  data.lease.status ===
                    LEASE_STATUS.LEASE_PENDING_CREDIT.status)) ? null : (
              <div key={file.key} className='file-container'>
                <div>
                  <div className='file-title'>{fileName}</div>
                  <div className='file-date'>
                    {`File added: ${moment(fileAdded).format(
                      'MMMM DD, YYYY h:mm A',
                    )}`}
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <Button
                    style={{ padding: '0 17px', margin: '0 10px' }}
                    onClick={() => viewExistingFile(file.key)}
                  >
                    <span role='img' aria-label='view-image'>
                      &#128269;
                    </span>
                  </Button>

                  {/*Render File Delete Button*/}
                  {renderDeleteButton(file)}
                </div>
              </div>
            )
          })}
        </div>
        {IS_ALLOWED_TO_ADD_FILES && (
          <FileUpload
            leaseMetaData={{ id: data.lease.id }}
            disabled={isDisabled}
            noStartingInput={!!files.length}
            type={type}
          />
        )}
      </div>
    </>
  )
}

LeaseFiles.propTypes = {
  data: propTypes.object,
  setData: propTypes.func,
  isDisabled: propTypes.bool,
  type: propTypes.string,
  IS_ADMIN: propTypes.bool,
}

export default LeaseFiles

import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import SEO from '../components/SEO'
import downArrow from '../images/down-arrow.svg'
import Layout from '../layouts/index'
import GoogleMap from '../components/GoogleMap'

const Home = ({ location }) => {
  const [background, setBackground] = useState('home-background-0')

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  const myRef = useRef(null)
  const executeScroll = () => scrollToRef(myRef)

  useEffect(() => {
    let i = 1
    const interval = setInterval(() => {
      i = i % 3
      setBackground(`home-background-${i}`)
      i++
    }, 8000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Layout location={location}>
      <SEO title='Home' />
      <Helmet>
        <meta
          name='description'
          content='Equipment Leasing, Equipment Financing, Equipment For Sale, Leasing Partner '
        />
      </Helmet>

      <div className={`home-background ${background}`} />
      <div>
        <div className='hero-container intro pb-4 flex flex-column w-100'>
          <div className='home-title'>
            <h1>ADD Capital Corp.</h1>
            <h2>Together we find the right solution to managing your assets</h2>
          </div>
          <div className='home-buttons col-12'>
            <div className='text-center'>
              <Link className='button button-clear mt-2' to='/about'>
                About Us
              </Link>
            </div>
            <div className='text-center'>
              <Link className='button button-clear mt-2' to='/for-sale'>
                For Sale
              </Link>
            </div>
            <div className='text-center'>
              <Link className='button button-clear mt-2' to='/payment'>
                Payment Centre
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 down-arrow' onClick={executeScroll}>
        <img alt='Down' src={downArrow} />
      </div>
      <GoogleMap />
      <div ref={myRef} />
    </Layout>
  )
}

Home.propTypes = {
  location: PropTypes.object,
}

export default Home

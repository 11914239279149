import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from 'gatsby'
import { ClipLoader } from 'react-spinners'

import { API, Storage, Util } from '../amplify'
import { parseLeaseFiles } from '../util'

import PortalPage from './PortalPage'
import LeaseToggle from './LeaseToggle'

import LeaseCreditForm from './LeaseCreditForm'
import LeaseContractForm from './LeaseContractForm'

const Lease = ({ user }) => {
  const leaseID =
    typeof window !== 'undefined' ? window.location.pathname.split('/')[3] : ''
  const IS_ADMIN = user?.attributes?.['custom:type'] === 'admin'

  const [data, _setData] = useState({
    loading: true,
    lease: {},
    broker: {},
    files: {
      credit: [],
      creditLog: [],
      contract: [],
      generatedContract: [],
    },
  })
  // wrapper setData function to do any extra data setter functionality
  const setData = newData => {
    // to get around amplify JSON bug
    // https://github.com/aws-amplify/amplify-js/issues/5767
    let parsedCoLessees = newData.lease.co_lessees
    if (typeof parsedCoLessees === 'string') {
      parsedCoLessees = JSON.parse(parsedCoLessees)
    }
    if (typeof parsedCoLessees === 'string') {
      parsedCoLessees = JSON.parse(parsedCoLessees)
    }
    // make sure co-lessees always have at least a blank email field corresponding
    const coLesseeKeys = Object.keys(parsedCoLessees)
    parsedCoLessees = coLesseeKeys.reduce((acc, field) => {
      let updatedCoLessees = { ...acc, ...parsedCoLessees }

      if (field.includes('co_lessee_name')) {
        const coLesseeNum = Number(field.split('_')[3])

        // if colessee map does not already have email defined, insert blank one
        if (!coLesseeKeys.includes(`co_lessee_email_${coLesseeNum}`)) {
          updatedCoLessees = {
            ...updatedCoLessees,
            [`co_lessee_email_${coLesseeNum}`]: '',
          }
        }
      }

      return updatedCoLessees
    }, parsedCoLessees)

    _setData({
      ...newData,
      lease: {
        ...newData.lease,
        co_lessees: parsedCoLessees,
        main_lessee_country: 'Canada', // somewhere this gets set to false, explicity set
      },
    })
  }

  const [leaseToggleState, setLeaseToggleState] = useState('credit')
  const [updateFormulaFields, setUpdateFormulaFields] = useState(false)
  const [didSubmitReview, setDidSubmitReview] = useState({
    credit: false,
    contract: false,
  })

  // do this permission checking in GQL instead? will save a request
  useEffect(() => {
    getBrokerId()
  }, [])

  // Do calculations for formula fields
  useEffect(() => {
    const calculatedFields = {}
    // Credit
    if (data.lease.equipment_cost) {
      calculatedFields.dp_percentage = (
        (Number(data.lease.dp_final) / Number(data.lease.equipment_cost)) *
        100
      ).toFixed(1)
    }

    calculatedFields.clbo_value =
      Number(data.lease.equipment_cost) -
      Number(data.lease.dp_final) -
      Number(data.lease.trade_in_value) +
      Number(data.lease.trade_u_value)

    calculatedFields.collateral_exposure =
      Number(calculatedFields.clbo_value) -
      Number(data.lease.equipment_value) -
      Number(data.lease.security_deposit) -
      Number(data.lease.additional_coll_value)

    calculatedFields.total_exposure =
      Number(calculatedFields.clbo_value) + Number(data.lease.exist_value)

    if (updateFormulaFields) {
      setData({
        ...data,
        lease: {
          ...data.lease,
          ...calculatedFields,
        },
      })
      setUpdateFormulaFields(false)
    }
  }, [data.lease])

  // main lease change handler that updates entire lease and is passed down to all forms
  const onLeaseInputChange = e => {
    if (!e.target.name) return

    // Replace empty strings with null
    let inputFieldValue = e.target.value
    if (e.target.value === '') {
      inputFieldValue = null
    }

    setData({
      ...data,
      lease: {
        ...data.lease,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : inputFieldValue,
      },
    })

    setUpdateFormulaFields(true)
  }

  // check if the broker can actually access this lease
  const getBrokerId = async () => {
    try {
      const response = (await API.getLeaseBroker(leaseID))?.data

      // if there is no lease at the specified URI, go back to portal
      if (!response?.getLease) {
        navigate('/portal')
      }

      if (!IS_ADMIN && response.getLease?.broker_id !== user.username) {
        navigate('/portal')
      } else {
        getLease(response.getLease.broker_id)
      }
    } catch (error) {
      Util.LogError('ERROR_FETCHING_LEASE_BROKER', error, leaseID)
    }
  }

  const getLease = async leaseBrokerId => {
    try {
      const leaseResponse = (
        await API.getLeaseAndBroker(leaseID, leaseBrokerId)
      ).data

      const filesResponse = await Storage.listDocuments(`leases/${leaseID}`)
      const sortedFiles = parseLeaseFiles(filesResponse)

      setLeaseToggleState(
        leaseResponse.getLease.status.includes('CREDIT')
          ? 'credit'
          : 'contract',
      )

      setData({
        loading: false,
        lease: leaseResponse.getLease,
        broker: leaseResponse.getUser,
        files: sortedFiles,
      })
    } catch (error) {
      Util.LogError('ERROR_FETCHING_LEASE_OR_FILES', error, `leases/${leaseID}`)
    }
  }

  return (
    <PortalPage
      user={user}
      headerLinks={[
        <Link className='button-danger' to='/portal/' key='backToLeaseLink'>
          Back to Applications
        </Link>,
      ]}
    >
      {data.loading ? (
        <div className='centeredContentPage'>
          <ClipLoader loading size='60px' color='#3b9453' />
        </div>
      ) : (
        <div className='d-flex flex-column w-100 px-3 pb-3'>
          <div className='dashboard-title'>{`${leaseToggleState.toUpperCase()} APPLICATION INFO`}</div>

          {/* Lease Toggle */}
          <LeaseToggle
            leaseToggleState={leaseToggleState}
            setLeaseToggleState={setLeaseToggleState}
          />

          {leaseToggleState === 'credit' ? (
            <LeaseCreditForm
              data={data}
              setData={setData}
              user={user}
              onLeaseInputChange={onLeaseInputChange}
              IS_ADMIN={IS_ADMIN}
              didSubmitReview={didSubmitReview}
              setDidSubmitReview={setDidSubmitReview}
            />
          ) : (
            <LeaseContractForm
              data={data}
              setData={setData}
              user={user}
              onLeaseInputChange={onLeaseInputChange}
              IS_ADMIN={IS_ADMIN}
              didSubmitReview={didSubmitReview}
              setDidSubmitReview={setDidSubmitReview}
            />
          )}
        </div>
      )}
    </PortalPage>
  )
}

Lease.propTypes = {
  user: PropTypes.object,
}

export default Lease

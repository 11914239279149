// KEEP IN SYNC WITH - amplify/backend/function/docusignHelper/src/index.js:DOCUSIGN_ACTIONS

export const DOCUSIGN_ACTIONS = {
  USERS_LIST: 'USERS_LIST',
  GET_USER: 'GET_USER',
  CREATE_ENVELOPE: 'CREATE_ENVELOPE',
  UPDATE_ENVELOPE: 'UPDATE_ENVELOPE',
  GET_TABS: 'GET_TABS',
  UPDATE_TABS: 'UPDATE_TABS',
  GET_ENVELOPE_STATUS: 'GET_ENVELOPE_STATUS',
  LIST_AUDIT_EVENTS: 'LIST_AUDIT_EVENTS',
  DOWNLOAD_ENVELOPE_DOCUMENTS: 'DOWNLOAD_ENVELOPE_DOCUMENTS',
}

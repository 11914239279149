import React from 'react'
import Layout from '../layouts/index'

import NotFound from '../components/NotFound'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <NotFound />
      </Layout>
    )
  }
}

export default NotFoundPage

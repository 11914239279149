import React from 'react'
import propTypes from 'prop-types'
import { ClipLoader } from 'react-spinners'

import Input from './Input'
import Button from './Button'

const LeaseNeedsInfo = ({
  needsInfoUpdate,
  submitNeedsInfoUpdate,
  lease,
  type,
}) => {
  const infoType = type === 'credit' ? 'credit application' : 'contract'

  return (
    <div className='info-block'>
      {!needsInfoUpdate.successful && (
        <>
          <b>Needs More Information</b>
          <div className='mb-5'>
            {`Your ${infoType} was denied as more information was needed. Please see the
            feedback and make the necessary changes to your lease ${infoType}.`}
          </div>
          <Input
            label='Admin Feedback:'
            textarea
            disabled
            value={
              type === 'credit'
                ? lease.admin_credit_feedback || ''
                : lease.admin_contract_feedback || ''
            }
            rows={8}
            boldLabel
          />
          {needsInfoUpdate.loading ? (
            <div className='col-12 mt-10 d-flex justify-content-center'>
              <ClipLoader loading size='40px' color='#3b9453' />
            </div>
          ) : (
            <Button
              onClick={submitNeedsInfoUpdate}
            >{`Resubmit ${type}`}</Button>
          )}
        </>
      )}
    </div>
  )
}

LeaseNeedsInfo.propTypes = {
  needsInfoUpdate: propTypes.object,
  lease: propTypes.object,
  submitNeedsInfoUpdate: propTypes.func,
  type: propTypes.string,
}

export default LeaseNeedsInfo

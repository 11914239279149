import { useState, useEffect } from 'react'

export function parseQuery(queryString) {
  const queryMap = {}
  const pairs = (
    queryString[0] === '#' ? queryString.substr(1) : queryString
  ).split('&')

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    queryMap[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }

  return queryMap
}

// splits list of all lease files into contract, credit, generatedContract and creditLog files
export function parseLeaseFiles(leaseFiles) {
  return leaseFiles.reduce(
    (acc, file) => {
      const fileType = file.key.split('/')[2]
      if (fileType === 'credit') {
        acc.credit.push(file)
      } else if (fileType === 'creditLog') {
        acc.creditLog.push(file)
      } else if (fileType === 'generatedContract') {
        acc.generatedContract.push(file)
      } else {
        acc.contract.push(file)
      }

      return acc
    },
    {
      credit: [],
      creditLog: [],
      contract: [],
      generatedContract: [],
    },
  )
}

// returns string of collessee names and emails
export function parseCoLessees(coLessees) {
  let coLesseeString = ''
  if (coLessees) {
    for (let i = 0; i < Object.keys(coLessees).length / 2; i++) {
      coLesseeString = `${coLesseeString}${
        coLessees[`co_lessee_name_${i + 1}`]
      }\n`
    }
  }
  return coLesseeString
}

// returns an array of all text separated by any amount newlines
export function splitMultiLineText(text, addSerial = false) {
  return (text || '')
    .split('\n')
    .filter(asset => !!asset)
    .map(asset => (addSerial ? `S/N: ${asset}` : asset))
}

// Check if any lessee emails are the same
export function emailsDuplicated(lease) {
  let duplicateEmails = false

  if (lease.co_lessees) {
    duplicateEmails =
      lease.main_lessee_email === lease.co_lessees.co_lessee_email_1

    if (lease.co_lessees.co_lessee_email_2) {
      duplicateEmails =
        duplicateEmails ||
        lease.co_lessees.co_lessee_email_2 ===
          lease.co_lessees.co_lessee_email_1 ||
        lease.co_lessees.co_lessee_email_2 === lease.main_lessee_email

      if (lease.co_lessees.co_lessee_email_3) {
        duplicateEmails =
          duplicateEmails ||
          lease.co_lessees.co_lessee_email_3 ===
            lease.co_lessees.co_lessee_email_2 ||
          lease.co_lessees.co_lessee_email_3 ===
            lease.co_lessees.co_lessee_email_1 ||
          lease.co_lessees.co_lessee_email_3 === lease.main_lessee_email
      }
    }
  }

  return duplicateEmails
}

/**
 * Takes a string number that could be formatted and returns the numerical value
 * @example "1,234,567.50" => 1234567.50
 * @param {String} numString
 * @returns {Number}
 */
export function toNumber(numString) {
  let parsedNum = numString
  if (typeof numString !== 'string') {
    parsedNum = String(numString)
  }
  parsedNum = parsedNum.split(',').join('')

  // make sure parsedNum is a convertable string
  if (Number(parsedNum)) {
    return Number(parsedNum)
  }
  return parsedNum
}

/**
 * Takes a number and returns the formatted value as a string
 * @example 1234567.50 => "1,234,567.50"
 * @param {Number} numFloat
 * @returns {String}
 */
export function formatNumber(numFloat) {
  if (!numFloat) {
    return numFloat
  }

  if (typeof numFloat !== 'number') {
    numFloat = toNumber(numFloat)
  }

  return numFloat.toLocaleString(undefined, { minimumFractionDigits: 2 })
}

// hook that returns if the current device width is within the specified breakpoint
const breakpoints = { sm: 576, md: 768, lg: 992, xl: 1200 }
export function isMobile(breakpoint = null) {
  const IS_BROWSER = typeof window !== 'undefined'
  const [_isMobile, _setIsMobile] = useState(false)

  const checkBreakpoint = () => {
    const width = breakpoints[breakpoint]
    if (width) {
      return _setIsMobile(window.innerWidth <= width)
    }

    if (typeof breakpoint === 'number') {
      return _setIsMobile(window.innerWidth <= breakpoint)
    }

    return _setIsMobile(false)
  }

  useEffect(() => {
    if (IS_BROWSER) {
      checkBreakpoint()
      window.addEventListener('resize', checkBreakpoint)
    }
    return () => {
      if (IS_BROWSER) {
        return window.removeEventListener('resize', checkBreakpoint)
      }
    }
  }, [])

  return _isMobile
}

// regex helper that returns if the passed string is a UUID or not
// mainly used to tell if a docusign or sign easy document ID. Docusign uses UUID
export function isUUID(str) {
  return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
    str,
  )
}

// pdf form field helper that attempts to get the field, set text and update font
export function getFieldSetTextMaybe(
  fieldName,
  value = '',
  useSmallerFont = false,
) {
  const field = this.getFieldMaybe(fieldName)

  if (field) {
    field.setText(value)

    if (useSmallerFont) {
      field.setFontSize(8)
    }
  }
}

export function truncate(string, charLimit = 4000) {
  return String(string || '').substring(0, charLimit)
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import ImageGallery from 'react-image-gallery'

import Layout from '../../layouts/index'
import SEO from '../../components/SEO'
import Button from '../../components/Button'

const ForSale = ({ data: { allMarkdownRemark } }) => {
  // O(n^2) ... make better if performance takes hit
  const saleItems = allMarkdownRemark.edges
    .filter(item => item.node.frontmatter.active === true)
    .reduce((acc, { node: { frontmatter, html } }) => {
      const images = Array.from(Array(frontmatter.imageCount).keys()).map(i => {
        const imageSource = `${frontmatter.imagePath}/${i + 1}.${
          frontmatter.imageExtension
        }`
        return {
          original: imageSource,
          originalClass: 'gallery-image',
          thumbnail: imageSource,
        }
      })

      return {
        ...acc,
        [frontmatter.imagePath]: {
          ...frontmatter,
          images,
          html,
        },
      }
    }, {})

  const [selectedItem, setSelectedItem] = useState(
    saleItems[Object.keys(saleItems)[0]]?.imagePath || null,
  )

  const interestedInItemHandler = () => {
    navigate('/contact', {
      state: {
        inquiryItem: saleItems[selectedItem],
      },
    })
  }

  return (
    <Layout bodyClass='gradient'>
      <SEO title='For Sale' />
      <div className='page-banner for-sale-background'>
        <div className='page-title'>For Sale</div>
      </div>

      <div className='container py-6'>
        <div
          className='box box-top w-100'
          style={{
            background: '#F2F2F2',
            width: '100%',
            borderRadius: '12px',
            border: '2px solid #3b9453',
          }}
        >
          <div className='for-sale-container'>
            <div className='for-sale-left-half'>
              <div className='for-sale-text-info'>
                <div className='for-sale-text-info-item'>
                  We have a wide range of equipment readily available to lease
                  and sell
                </div>
                <div className='for-sale-text-info-item'>
                  For all inquiries please click the inquire button under the
                  listing to contact us about a listing
                </div>
                <div className='for-sale-text-info-item'>
                  Check out some of our currently listed items below!
                </div>
              </div>
              <div className='for-sale-item-picker'>
                {Object.keys(saleItems).map(itemKey => (
                  <div
                    className={`for-sale-item ${
                      selectedItem === itemKey && 'for-sale-item-selected'
                    }`}
                    onClick={() => setSelectedItem(itemKey)}
                    key={itemKey}
                  >
                    <img
                      className='for-sale-item-preview'
                      src={saleItems[itemKey].images[0].original}
                      alt={saleItems[itemKey].title}
                    />
                    <div className='for-sale-item-title'>
                      <b>{saleItems[itemKey].title}</b>
                      {saleItems[itemKey].year}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='for-sale-right-half'>
              <div className='for-sale-gallery-container'>
                <div className='for-sale-gallery-content'>
                  <h3 style={{ textAlign: 'center', paddingBottom: '15px' }}>
                    <u>
                      {saleItems[selectedItem]?.title ||
                        'No current items For Sale'}
                    </u>
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: saleItems[selectedItem]?.html,
                    }}
                  />
                  {saleItems[selectedItem] && (
                    <div className='for-sale-year-location'>
                      <div className='for-sale-year-location-item'>{`Year: ${saleItems[selectedItem]?.year}`}</div>
                      <div className='for-sale-year-location-item'>{`Location: ${saleItems[selectedItem]?.location}`}</div>
                    </div>
                  )}
                </div>
                {saleItems[selectedItem] && (
                  <>
                    <Button
                      style={{ margin: '10px 0 20px 0' }}
                      onClick={interestedInItemHandler}
                    >
                      Inquire about this item
                    </Button>
                    <div className='for-sale-image-gallery'>
                      <ImageGallery
                        items={saleItems[selectedItem]?.images || []}
                        showPlayButton={false}
                        lazyLoad
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ForSaleQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/for-sale/" } }
      sort: { fields: frontmatter___title }
    ) {
      edges {
        node {
          html
          frontmatter {
            active
            imagePath
            imageCount
            imageExtension
            title
            year
            location
          }
        }
      }
    }
  }
`

ForSale.propTypes = {
  data: PropTypes.object,
}

export default ForSale

import React, { useRef, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import SEO from '../../components/SEO'
import Layout from '../../layouts/index'
import EmailForm from '../../components/EmailForm'
import GoogleMap from '../../components/GoogleMap'
import ContactCard from '../../components/ContactCard'

const Contact = props => {
  const contactInfo = props.data.allMarkdownRemark.edges.reduce(
    (acc, profile) => {
      return {
        ...acc,
        [profile.node.frontmatter.name]: {
          ...profile.node.frontmatter,
          body: profile.node.excerpt,
          html: profile.node.html,
        },
      }
    },
    {},
  )
  const markhamInfo = contactInfo.Markham
  const burlingtonInfo = contactInfo.Burlington

  const inquiryItem = props?.location?.state?.inquiryItem

  const [contactCard, setContactCard] = useState(null)

  const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop)
  const salesInquiryRef = useRef(null)

  useEffect(() => {
    setTimeout(() => inquiryItem && scrollToRef(salesInquiryRef), 50)
  }, [])

  return (
    <Layout bodyClass='gradient'>
      <SEO title='Contact' />
      <div className='page-banner contact-background'>
        <div className='page-title'>Contact</div>
      </div>

      <div className='container pt-3'>
        <div className='row contact-wrapper'>
          <div className='col-sm-12 col-md-6'>
            <div className='contact'>
              <div className='contact-meta'>
                <h2 className='contact-title'>{markhamInfo.name}</h2>
                <hr />
              </div>
              <hr />
              <div
                className='contact-content contact-profile'
                onMouseEnter={() => setContactCard(contactInfo['Rick Browne'])}
                onMouseLeave={() => setContactCard(null)}
              >
                Rick Browne, Founder
              </div>
              <div
                className='contact-content contact-profile'
                onMouseEnter={() =>
                  setContactCard(contactInfo['Bill Patterson'])
                }
                onMouseLeave={() => setContactCard(null)}
              >
                Bill Patterson, President
              </div>
              <div
                className='contact-content contact-profile'
                onMouseEnter={() => setContactCard(contactInfo['Heather Park'])}
                onMouseLeave={() => setContactCard(null)}
              >
                Heather Park, Controller
              </div>
              <div
                className='contact-content contact-profile'
                onMouseEnter={() =>
                  setContactCard(contactInfo['Shelley Saunders'])
                }
                onMouseLeave={() => setContactCard(null)}
              >
                Shelley Saunders, Senior VP and GM
              </div>
              <div
                className='contact-content contact-employees'
                dangerouslySetInnerHTML={{ __html: markhamInfo.html }}
              />
            </div>
            <div className='contact pt-4'>
              <div ref={salesInquiryRef} />
              <div className='contact-meta'>
                <h2 className='contact-title'>{burlingtonInfo.name}</h2>
                <hr />
              </div>
              <hr />
              <div
                className='contact-content contact-profile'
                onMouseEnter={() => setContactCard(contactInfo['Dave Ralph'])}
                onMouseLeave={() => setContactCard(null)}
              >
                Dave Ralph, Partner, ext. 400
              </div>
              <div
                className='contact-content contact-employees'
                dangerouslySetInnerHTML={{ __html: burlingtonInfo.html }}
              />
            </div>
          </div>
          <div className='contact-form col-md-6 col-sm-12'>
            {contactCard && (
              <ContactCard
                name={contactCard.name}
                title={contactCard.title}
                email={contactCard.email}
                phone={contactCard.phone}
                body={contactCard.body}
              />
            )}

            <EmailForm
              predefinedData={{
                inquiryItem,
              }}
            />
          </div>
        </div>
      </div>
      <GoogleMap />
    </Layout>
  )
}

export const query = graphql`
  query ContactQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/contact/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          html
          excerpt(pruneLength: 1000)
          frontmatter {
            path
            name
            title
            email
            phone
          }
        }
      }
    }
  }
`
Contact.propTypes = {
  data: PropTypes.object,
}

export default Contact

import React from 'react'
import { graphql, Link, StaticQuery } from 'gatsby'

import Menu from './Menu'

const Footer = props => (
  <div className='footer-strip'>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <div className='footer'>
            <h3 className='footer-title'>
              © {new Date().getFullYear()} {props.data.site.siteMetadata.title}
            </h3>
            <Menu />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
)

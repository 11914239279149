import React, { useState, useEffect } from 'react'
import { ClipLoader } from 'react-spinners'
import { Router } from '@reach/router'
import { ToastContainer } from 'react-toastify'

import { Auth, API, Util } from '../../amplify'
import { isMobile } from '../../util/helpers'

import Layout from '../../layouts/index'
import SEO from '../../components/SEO'
import PrivateRoute from '../../components/PrivateRoute'
import LoginForm from '../../components/Login'
import BrokerHome from '../../components/BrokerHome'
import Lease from '../../components/Lease'
import NewLease from '../../components/NewLease'
import LeaseOverview from '../../components/LeaseOverview'
import NotFound from '../../components/NotFound'

import 'react-toastify/dist/ReactToastify.css'

const Portal = () => {
  const BLOCK_MOBILE_ACCESS = false
  const IS_MOBILE = isMobile('md')

  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({})
  const [brokerList, setBrokerList] = useState([])
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [selectedBrokerFilter, setSelectedBrokerFilter] = useState([
    {
      label: 'Select a Broker Filter...',
      value: null,
    },
  ])

  useEffect(() => {
    refreshAuthStatus()
  }, [])

  const fetchBrokers = async () => {
    try {
      const userResponse = await API.listUsers({
        filter: {
          type: {
            ne: 'admin',
          },
        },
      })

      const brokers = [
        { label: 'No Filter', value: '' },
        ...(userResponse?.data?.listUsers?.items || []).map(broker => ({
          label: broker.name,
          value: broker.id,
          email: broker.email,
        })),
      ]

      setBrokerList(brokers)
    } catch (error) {
      Util.LogError('ERROR_FETCHING_BROKER_FILTER_OPTIONS', error, user)
    }
  }

  const refreshAuthStatus = async () => {
    try {
      const authUser = await Auth.authStatus()

      setUser(authUser)
      setIsLoggedIn(!!authUser?.attributes)
      setIsLoading(false)

      if (authUser.attributes?.['custom:type'] === 'admin') {
        fetchBrokers()
      }
    } catch (error) {
      Util.LogError('ERROR_REFRESHING_AUTH_STATUS', error)
    }
  }

  return (
    <Layout bodyClass='gradient'>
      <SEO title='Broker Portal' />
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className='container'>
        {BLOCK_MOBILE_ACCESS && IS_MOBILE ? (
          <div className='row flex justify-content-center'>
            <div className=' auth mobile-portal text-center p-5'>
              <h1>
                Oops! This portal can only be accessed via desktop. Mobile usage
                is not supported at this time
              </h1>
            </div>
          </div>
        ) : (
          <div className='row'>
            {isLoading ? (
              <div className='col-12 mt-10 d-flex justify-content-center'>
                <ClipLoader loading size='60px' color='#3b9453' />
              </div>
            ) : (
              <div className='col-12'>
                <Router basepath='/portal'>
                  <PrivateRoute
                    path='/'
                    component={BrokerHome}
                    isLoggedIn={isLoggedIn}
                    user={user}
                    refreshAuthStatus={refreshAuthStatus}
                    brokerList={brokerList}
                    selectedBrokerFilter={selectedBrokerFilter}
                    setSelectedBrokerFilter={setSelectedBrokerFilter}
                    IS_MOBILE={IS_MOBILE}
                  />

                  <PrivateRoute
                    path='/lease/new'
                    component={NewLease}
                    isLoggedIn={isLoggedIn}
                    user={user}
                    refreshAuthStatus={refreshAuthStatus}
                    brokerList={brokerList.filter((_, i) => i !== 0)}
                  />

                  <PrivateRoute
                    path='/lease/:id'
                    component={Lease}
                    isLoggedIn={isLoggedIn}
                    user={user}
                  />

                  <PrivateRoute
                    path='/leases/:status'
                    component={LeaseOverview}
                    isLoggedIn={isLoggedIn}
                    user={user}
                    refreshAuthStatus={refreshAuthStatus}
                    brokerList={brokerList}
                    selectedBrokerFilter={selectedBrokerFilter}
                    setSelectedBrokerFilter={setSelectedBrokerFilter}
                  />

                  <LoginForm
                    path='/login'
                    refreshAuthStatus={refreshAuthStatus}
                    isLoggedIn={isLoggedIn}
                  />

                  <NotFound default />
                </Router>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Portal
